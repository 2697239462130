import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  groupsContactState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
  postApiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
};

export const groupContactsSlice = createSlice({
  name: "groupContacts",
  initialState: INITIAL_STATE,
  reducers: {
    fetchGroupContacts: (state) => {
      state.groupsContactState.isLoading = true;
    },
    fetchGroupContactsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      groupsContactState: {
        ...state.groupsContactState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchGroupContactsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      groupsContactState: {
        ...state.groupsContactState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    addContactGroup: (state) => {
      state.groupsContactState.isLoading = true;
    },
    addContactGroupSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      groupsContactState: {
        ...state.groupsContactState,
        error: null,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    addContactGroupFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      groupsContactState: {
        ...state.groupsContactState,
        error: action.payload?.message,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearGroupContacts: (state) => ({
      ...state,
      groupsContactState: {
        ...state.groupsContactState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: false,
      },
    }),
  },
});

export const {
  fetchGroupContacts,
  fetchGroupContactsSuccess,
  fetchGroupContactsFailure,
  addContactGroup,
  addContactGroupFailure,
  addContactGroupSuccess,
  clearGroupContacts,
} = groupContactsSlice.actions;
export const groupContactsReducer = groupContactsSlice.reducer;
