import {
  DeleteOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AutoResponderLayout from "src/layouts/auto-responder";
import AutoResponder from "src/components/autoresponder";
import WorkDaysModal from "src/components/workDaysModal";
import {
  calculateTimeDifferenceInAMPM,
  formatTimeToAMPM,
} from "src/constants/functions";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import { getWorkingDaysData } from "src/store/selectors/features/workingDays";
import RequestAppAction from "src/store/slices/appActions";
import { clearWorkingDays } from "src/store/slices/features/workingDays";
import Colors from "src/themes/colors";
import "../../setting.scss";

const { Title, Text } = Typography;

interface workDaysRefProps {
  openModal: () => void;
  closeModal: () => void;
}

const TimeOfDay: React.FC = () => {
  const { t } = useTranslation();
  const workDaysRef = useRef<workDaysRefProps>(null);
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const [editCustom, setEditCustom] = useState(false);
  const [editValues, setEditValues] = useState<any>(null);
  const workDays: any = useSelector(getWorkingDaysData);
  const [disabled, setDisabled] = useState(false);
  const did = useSelector(getCurrentDidId);
  const dispatch = useDispatch();

  const hideModal = () => {
    setOpen(false);
  };

  const showEditCustom = (i: any) => {
    setEditValues(i);
    form.setFieldsValue({ ...i });
    setEditCustom(true);
  };

  const hideEditCustom = () => {
    form.resetFields();
    setEditValues({});
    setEditCustom(false);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((val: any) => {
        const params: {
          content: string;
          workingHours: any[];
          weekday: number[];
          twentyFourHours?: boolean;
        } = {
          content: val.content?.trim(),
          workingHours: editValues?.WorkingHours,
          weekday: editValues?.weekday,
        };

        editValues?.WorkingHours?.map((i: any) => {
          var [hoursFrom, minutesFrom] = i?.from.split(":").map(Number);
          var [hoursTo, minutesTo] = i?.to.split(":").map(Number);

          const fromTime = dayjs()
            .set("hours", hoursFrom)
            .set("minutes", minutesFrom);
          const toTime = dayjs()
            .set("hours", hoursTo)
            .set("minutes", minutesTo);

          if (calculateTimeDifferenceInAMPM(fromTime, toTime)) {
            params["twentyFourHours"] = true;
            return;
          } else {
            params["twentyFourHours"] = false;
            return;
          }
        });

        dispatch(
          RequestAppAction.putWorkingDays({
            id: editValues?.id,
            data: params,
            cbSuccess: () => {
              dispatch(RequestAppAction.getWorkingDays({ id: did }));
              notification.success({ message: t("notification.success") });
              hideEditCustom();
            },
            cbFailure: (e: string) => {
              notification.error({ message: e });
            },
          })
        );
      })
      .catch(() => {});
  };

  const options = [
    {
      value: "3",
      label: "Communicated",
    },
    {
      value: "4",
      label: "Identified",
    },
    {
      value: "5",
      label: "Resolved",
    },
  ];

  useEffect(() => {
    if (did) {
      dispatch(RequestAppAction.getWorkingDays({ id: did }));
    }
  }, [did]);

  const weekDays = [
    { name: "SUN", index: 0 },
    { name: "MON", index: 1 },
    { name: "TUE", index: 2 },
    { name: "WED", index: 3 },
    { name: "THU", index: 4 },
    { name: "FRI", index: 5 },
    { name: "SAT", index: 6 },
  ];

  const onDelete = (id: any) => {
    Modal.confirm({
      title: t("button.delete"),
      content: t("modal.confirmDelete"),
      okType: "danger",
      icon: null,
      className: "p-0",
      okButtonProps: {
        style: { backgroundColor: Colors.red, color: Colors.white },
      },
      style: { borderRadius: 10 },
      width: "30%",

      onOk: () => {
        dispatch(
          RequestAppAction.deleteWorkingDays({
            id: id,
            cbSuccess: () => {
              notification.success({ message: t("notification.success") });
              dispatch(RequestAppAction.getWorkingDays({ id: did }));
            },
            cbFailure: (e: string) => {
              notification.error({ message: e });
            },
          })
        );
      },
    });
  };

  const getName = () => {
    const match = weekDays.find((day) => day.index === editValues?.weekday);

    return match?.name;
  };

  useEffect(() => {
    if (!editCustom) {
      form.resetFields();
      setEditValues({});
      setDisabled(false);
    }
  }, [editCustom]);

  const disabledHours = () => {
    const currentHour = dayjs().hour();
    return Array.from({ length: currentHour }, (_, i) => i);
  };

  const disabledMinutes = (selectedHour: number) => {
    const currentHour = dayjs().hour();
    if (selectedHour === currentHour) {
      const currentMinute = dayjs().minute();
      return Array.from({ length: currentMinute }, (_, i) => i);
    }
    return [];
  };

  useEffect(() => {
    return () => {
      dispatch(clearWorkingDays());
    };
  }, []);

  useEffect(() => {
    if (editValues) {
      editValues?.WorkingHours?.map((i: any) => {
        var [hoursFrom, minutesFrom] = i?.from.split(":").map(Number);
        var [hoursTo, minutesTo] = i?.to.split(":").map(Number);

        const fromTime = dayjs()
          .set("hours", hoursFrom)
          .set("minutes", minutesFrom);
        const toTime = dayjs().set("hours", hoursTo).set("minutes", minutesTo);

        if (calculateTimeDifferenceInAMPM(fromTime, toTime)) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      });
    }
  }, [editValues]);

  return (
    <AutoResponderLayout classname="time-page">
      <AutoResponder />
      <div style={{ padding: "1.75rem" }} className="time-card">
        <Row className="d-flex justify-content-center">
          <Col
            style={{ padding: "0rem 0.5rem" }}
            span={24}
            className="d-flex align-items-center justify-content-left pb-5 font-weight-300"
          >
            <Space direction="vertical" size={10}>
              <Title level={5}>{t("heading.businessHours")}</Title>
              <Text className="font-size-14 font-weight-500">
                {t(
                  "common.selectTheTimesThatYouWantYourAutoresponderToBeActive"
                )}
              </Text>
              <Space direction="horizontal" size={8}>
                <Button
                  type="primary"
                  onClick={() => {
                    workDaysRef?.current?.openModal();
                  }}
                >
                  {t("button.batchSetAutoresponder")}
                </Button>
              </Space>
            </Space>
          </Col>
          <Col span={24}>
            <div
              style={{
                height: "20rem",
                overflowY: "auto",
                width: "100%",
                padding: "0.5rem 0.5rem",
              }}
            >
              {Array.isArray(workDays) && workDays?.length > 0 ? (
                workDays
                  ?.slice()
                  ?.sort((a, b) => {
                    if (a?.weekday === 0 && b?.weekday !== 0) return 1;
                    if (a?.weekday !== 0 && b?.weekday === 0) return -1;
                    return a?.weekday - b?.weekday;
                  })
                  ?.map((i, index) => {
                    const match = weekDays.find(
                      (day) => day.index === i.weekday
                    );

                    return (
                      <Card
                        key={index}
                        style={{
                          marginTop: index != 0 ? "0.8rem" : " 0rem",
                        }}
                        className="time-card-shadow"
                      >
                        <Space className="d-flex align-items-center justify-content-between">
                          <Title level={5}> {match?.name}</Title>
                          <Space size={20}>
                            <Button
                              icon={<EditOutlined />}
                              style={{ borderRadius: 50 }}
                              onClick={() => showEditCustom(i)}
                            />
                            <Button
                              onClick={() => onDelete(i?.id)}
                              icon={<DeleteOutlined />}
                              style={{ borderRadius: 50 }}
                            />
                          </Space>
                        </Space>
                        <Space className="font-size-13 font-weight-300">
                          {i?.content}
                        </Space>
                        <div>
                          <Space className="font-size-13 font-weight-300">
                            {i?.WorkingHours?.map(
                              (
                                {
                                  from,
                                  to,
                                }: {
                                  from: string;
                                  to: string;
                                },
                                index: number
                              ) => (
                                <Space className="font-size-13 font-weight-300">
                                  <Text
                                    style={{
                                      color: Colors.TitleGray,
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {formatTimeToAMPM(from)}
                                  </Text>
                                  /
                                  <Text
                                    style={{
                                      color: Colors.TitleGray,
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {formatTimeToAMPM(to)}
                                  </Text>
                                  {i?.WorkingHours.length !== index + 1
                                    ? ","
                                    : ""}
                                </Space>
                              )
                            )}
                          </Space>
                        </div>
                      </Card>
                    );
                  })
              ) : (
                <Space
                  style={{
                    height: "18rem",
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <Text
                        style={{
                          display: "flex",
                          alignItems: "center",
                          overflowY: "auto",
                          justifyContent: "center",
                          paddingTop: "4rem",
                          color: "#ccc",
                          fontSize: "1rem",
                          fontWeight: 300,
                        }}
                      >
                        {t("common.noListMain")}
                      </Text>
                    </div>
                  </div>
                </Space>
              )}
            </div>
          </Col>
          <Col
            span={24}
            className="d-flex align-items-center justify-content-right pt-4 font-weight-300"
          ></Col>
        </Row>
      </div>

      <Modal
        title={
          <Title level={4} className="font-size-20 font-weight-500">
            {t("modalHeading.customSettings")}
          </Title>
        }
        open={open}
        centered
        onOk={hideModal}
        onCancel={hideModal}
        okText={t("button.save")}
        cancelText={t("button.cancel")}
        className="time-custom-setting"
      >
        <div className="pt-5 pb-5">
          <Space direction="vertical" size={30} style={{ width: "100%" }}>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              options={options}
            />
            <Space direction="vertical" size={8}>
              <Title level={5} className="font-size-16 font-weight-500">
                {" "}
                {t("heading.setAutoresponderTime")}{" "}
              </Title>
              <Space wrap>
                <TimePicker
                  use12Hours
                  placeholder={t("timePicker.startTime")}
                  format="h:mm A"
                  disabledTime={() => ({
                    disabledHours,
                    disabledMinutes,
                  })}
                />
                <TimePicker
                  use12Hours
                  placeholder={t("timePicker.endTime")}
                  format="h:mm A"
                  disabledTime={() => ({
                    disabledHours,
                    disabledMinutes,
                  })}
                />
              </Space>
            </Space>
            <div className="text-center">
              <Button type="primary" icon={<PlusCircleOutlined />}>
                {" "}
                {t("button.addAddiionalWorkingHours")}{" "}
              </Button>
            </div>
            <div>
              <Form.Item initialValue={""} name="content">
                <Input.TextArea
                  rows={4}
                  placeholder=""
                  style={{ resize: "none" }}
                />
              </Form.Item>
            </div>
          </Space>
        </div>
      </Modal>

      <Modal
        title={
          <Title level={4} className="font-size-20 font-weight-500">
            {t("modalHeading.editCustomSettings", { day: getName() })}
          </Title>
        }
        open={editCustom}
        destroyOnClose={true}
        onOk={onFinish}
        onCancel={hideEditCustom}
        okText={t("button.save")}
        cancelText={t("button.cancel")}
        className="time-custom-setting"
      >
        <div className="pt-5 pb-5">
          <Form form={form} name="editForms" requiredMark={false}>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
              <Space direction="vertical" size={2}>
                <Title level={5} className="font-size-16 font-weight-500">
                  {" "}
                  {t("heading.setAutoresponderTime")}{" "}
                </Title>
                {editValues?.WorkingHours?.map((i: any, index: number) => {
                  var [hoursFrom, minutesFrom] = i?.from
                    .split(":")
                    .map(Number);
                  var [hoursTo, minutesTo] = i?.to.split(":").map(Number);

                  form.setFieldsValue({
                    [`from_${index}`]: dayjs()
                      .set("hours", hoursFrom)
                      .set("minutes", minutesFrom),
                    [`to_${index}`]: dayjs()
                      .set("hours", hoursTo)
                      .set("minutes", minutesTo),
                  });
                  return (
                    <div key={index}>
                      <Space key={index} wrap>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: t("fields.timeError"),
                            },
                          ]}
                          name={`from_${index}`}
                        >
                          <TimePicker
                            allowClear={false}
                            disabled={disabled}
                            placeholder={t("timePicker.startTime")}
                            onChange={(selectedTime) => {
                              let hour = dayjs(selectedTime).get("hours");
                              let min = dayjs(selectedTime).get("minutes");
                              setEditValues((prevValues: any) => {
                                const updatedWorkingHours = [
                                  ...prevValues.WorkingHours,
                                ];
                                updatedWorkingHours[index] = {
                                  ...updatedWorkingHours[index],
                                  from: `${hour < 10 ? "0" + hour : hour}:${
                                    min < 10 ? "0" + min : min
                                  }`,
                                };
                                return {
                                  ...prevValues,
                                  WorkingHours: updatedWorkingHours,
                                };
                              });
                            }}
                            use12Hours
                            format="h:mm A"
                          />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: t("fields.timeError"),
                            },
                          ]}
                          name={`to_${index}`}
                        >
                          <TimePicker
                            allowClear={false}
                            placeholder={t("timePicker.endTime")}
                            disabled={disabled}
                            onChange={(selectedTime) => {
                              let hour = dayjs(selectedTime).get("hours");
                              let min = dayjs(selectedTime).get("minutes");
                              setEditValues((prevValues: any) => {
                                const updatedWorkingHours = [
                                  ...prevValues.WorkingHours,
                                ];
                                updatedWorkingHours[index] = {
                                  ...updatedWorkingHours[index],
                                  to: `${hour < 10 ? "0" + hour : hour}:${
                                    min < 10 ? "0" + min : min
                                  }`,
                                };
                                return {
                                  ...prevValues,
                                  WorkingHours: updatedWorkingHours,
                                };
                              });
                            }}
                            use12Hours
                            format="h:mm A"
                          />
                        </Form.Item>
                        <div style={{ marginBottom: "2rem" }}>
                          {index !== 0 && (
                            <MinusCircleOutlined
                              onClick={() => {
                                setEditValues((pre: any) => ({
                                  ...pre,
                                  WorkingHours:
                                    editValues?.WorkingHours.filter(
                                      (_: any, ind: any) => ind !== index
                                    ),
                                }));
                              }}
                            />
                          )}
                        </div>
                        {index === 0 ? (
                          <div style={{ marginBottom: "1.6rem" }}>
                            <Checkbox
                              checked={disabled}
                              onChange={(e) => {
                                setTimeout(() => {
                                  setDisabled(!disabled);
                                }, 50);

                                if (e.target.checked) {
                                  setEditValues((prevValues: any) => ({
                                    ...prevValues,
                                    WorkingHours: [
                                      {
                                        from: dayjs()
                                          .set("hour", 0)
                                          .set("minute", 0)
                                          .format("HH:mm"),
                                        to: dayjs()
                                          .set("hour", 23)
                                          .set("minute", 59)
                                          .format("H:mm"),
                                      },
                                    ],
                                  }));
                                }
                              }}
                              style={{ marginLeft: "0.5rem" }}
                              className="font-size-12 font-weight-400"
                            >
                              {t("common.24Hours")}
                            </Checkbox>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Space>
                    </div>
                  );
                })}
              </Space>
              <div className="text-center">
                <Button
                  disabled={disabled}
                  onClick={() => {
                    setEditValues((pre: any) => ({
                      ...pre,
                      WorkingHours: [
                        ...editValues?.WorkingHours,
                        {
                          from: dayjs()
                            .set("hour", 0)
                            .set("minute", 0)
                            .format("HH:mm"),
                          to: dayjs()
                            .set("hour", 0)
                            .set("minute", 0)
                            .format("H:mm"),
                        },
                      ],
                    }));
                  }}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  {" "}
                  {t("button.addAddiionalWorkingHours")}{" "}
                </Button>
              </div>
              <div>
                <Form.Item initialValue={editValues?.content} name="content">
                  <Input.TextArea
                    rows={4}
                    placeholder=""
                    style={{ resize: "none" }}
                  />
                </Form.Item>
              </div>
            </Space>
          </Form>
        </div>
      </Modal>
      <WorkDaysModal
        weekDays={weekDays
          ?.slice()
          ?.sort((a: { index: number }, b: { index: number }) => {
            if (a?.index === 0 && b?.index !== 0) return 1;
            if (a?.index !== 0 && b?.index === 0) return -1;
            return a?.index - b?.index;
          })}
        ref={workDaysRef}
      />
    </AutoResponderLayout>
  );
};

export default TimeOfDay;
