import React, { ReactNode } from "react";
import { Button as AntdButton } from "antd";
import { ButtonShapeType, HtmlButtonType } from "src/constants/types";
import styles from "./button.module.scss";

interface MyButtonProps {
  disabled?: boolean;
  title?: string;
  icon?: ReactNode;
  buttonType?: HtmlButtonType;
  onBtnClick?: (value: any) => void;
  shape?: ButtonShapeType;
  btnClass?: string;
  loading?: boolean;
}

const Button = ({
  title,
  icon,
  onBtnClick,
  shape = "default",
  btnClass = "filled_btn",
  buttonType,
  loading = false,
  disabled = false,
}: MyButtonProps) => {
  return shape === "circle" ? (
    <AntdButton
      onClick={onBtnClick}
      shape="circle"
      icon={icon}
      disabled={disabled}
    ></AntdButton>
  ) : (
    <AntdButton
      className={styles[btnClass]}
      shape={shape}
      htmlType={buttonType}
      onClick={onBtnClick}
      loading={loading}
      disabled={disabled}
    >
      <span className={styles.btn_child}>
        {icon}
        {title}
      </span>
    </AntdButton>
  );
};

export default Button;
