import { createSelector } from "reselect";

const templateSelector = (state: TReduxState) => state.features.postTemplate;

export const getBaseUrl = createSelector(templateSelector, (app) => app.baseUrl);

export const getPostTemplateData = createSelector(
  templateSelector,
  (app) => app.apiStatus.data
);

export const getPostTemplateState = createSelector(
  templateSelector,
  (app) => app.template
);

export const getPostTemplateLoading = createSelector(
  getPostTemplateState,
  (states) => states?.isLoading
);

export const getPutTemplateLoading = createSelector(
  getPostTemplateState,
  (states) => states?.isLoading
);

export const getDeleteTemplateLoading = createSelector(
  getPostTemplateState,
  (states) => states?.isLoading
);
