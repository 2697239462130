import React from "react";
import { Card, Space } from "antd";
import "./settings.scss";

const SettingsLayout = ({
  isCard = true,
  classname = "",
  children,
}: {
  isCard?: boolean,
  classname?: string,
  children: React.ReactNode,
}) => {
  return (
    <div className="settings-page d-flex align-items-center justify-content-center">
      {isCard ? (
        <Card className={`${classname} settings-container settings-card card-shadow`}>
          {children}
        </Card>
      ) : (
        <Space className={`${classname} settings-container settings-space`}>
          {children}
        </Space>
      )}
    </div>
  );
};

export default SettingsLayout;
