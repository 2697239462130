import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";

import {
  putChangePasswordFailure,
  putChangePasswordSuccess,
} from "../slices/features/changePassword";
import { RequestTypes } from "../types";

const appService = new AppService();

function* putChangePassword(action: any) {
  const { payload } = action;
  const { data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.putChangePassword,
      baseUrl,
      data
    );
    if (response) {
      yield put(putChangePasswordSuccess({ ...response }));
      payload?.cbSuccess && payload?.cbSuccess();
    }
  } catch (error: any) {
    const { statusCode, statusText } = error;
    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);
    yield put(putChangePasswordFailure({ statusCode, statusText }));
  }
}

export function* watchPutChangePassword() {
  yield takeLatest(RequestTypes.PUT_CHANGE_PASSWORD, putChangePassword);
}
