import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchScheduleMessageFailure,
  fetchScheduleMessageSuccess,
} from "../slices/features/scheduleMessages";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchScheduleMessageSaga(action: any) {
  const { payload } = action;
  const { id, meta } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchScheduleMessage,
      baseUrl,
      id,
      meta
    );

    yield put(
      fetchScheduleMessageSuccess({
        ...response,
        items: [],
      })
    );

    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchScheduleMessageFailure({ statusCode, statusText }));
  }
}

export function* watchFetchScheduleMessage() {
  yield takeLatest(
    RequestTypes.GET_SCHEDULE_MSG_REQUEST,
    fetchScheduleMessageSaga
  );
}
