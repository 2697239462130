import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";

interface modalProps {
  title?: string;
  children?: ReactNode;
  okText?: string;
  className?: any;
  afterClose?: () => void;
}

export const TextifyModal = forwardRef(
  ({ children, className, afterClose }: modalProps, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    useImperativeHandle(ref, () => ({
      openModal: (val?: string) => (
        setIsVisible(true), setModalTitle(val || "")
      ),
      closeModal: () => setIsVisible(false),
    }));

    return (
      <Modal
        style={{ zIndex: 500 }}
        title={modalTitle}
        centered
        destroyOnClose
        afterClose={afterClose}
        open={isVisible}
        closeIcon={
          <CloseOutlined
            onClick={() => {
              setIsVisible(false);
            }}
          />
        }
        className={`${className}`}
        //modal zIndex will not work if >=1000
        zIndex={1111}
        footer={false}
      >
        {children}
      </Modal>
    );
  }
);
