import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { putAutoresponderKeywordFailure, putAutoresponderKeywordSuccess } from "../slices/features/postAutoresponderKeyword";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* putAutoresponderKeywordSaga(action: any) {
  const { payload } = action;
  const { id, data } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.putAutoresponderKeyword,
      baseUrl,
      id,
      data
    );

    yield put(putAutoresponderKeywordSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(putAutoresponderKeywordFailure({ statusCode, statusText }));
  }
}

export function* watchPutAutoresponderKeyword() {
  yield takeLatest(RequestTypes.PUT_AUTORESPONDER_KEYWORD_REQUEST, putAutoresponderKeywordSaga);
}
