import { Route, Routes } from "react-router-dom";
import ForgotPassword from "src/pages/auth/forgot-password";
import Login from "src/pages/auth/login";
import ResetPassword from "src/pages/auth/reset-password";
import TokenExpire from "src/pages/auth/token-expire";
import Dashboard from "src/pages/dashboard";

const PublicRoutes = ({ loggedIn }: { loggedIn: boolean }) => (
  <div className="dashboard_wrapper">
    <Routes>
      <Route path="/*" element={loggedIn ? <Dashboard /> : <Login />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/signup" element={<Login />}></Route>
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route path="/set-password/:id" element={<ResetPassword />}></Route>
      <Route path="/token-expired" element={<TokenExpire />}></Route>
    </Routes>
  </div>
);

export default PublicRoutes;
