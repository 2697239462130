import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  addContactGroupFailure,
  addContactGroupSuccess,
} from "../slices/features/groupContacts";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* addGroupContactsSaga(action: any) {
  const { payload } = action;
  const { id, groupId, data } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.addGroupContacts,
      baseUrl,
      id,
      groupId,
      data
    );

    yield put(addContactGroupSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(addContactGroupFailure({ statusCode, statusText }));
  }
}

export function* watchAddGroupContact() {
  yield takeLatest(
    RequestTypes.ADD_CONTACT_GROUP_REQUEST,
    addGroupContactsSaga
  );
}
