import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  autoresponderKeyword: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: [],
  },
};

export const AutoresponderKeywordSlice = createSlice({
  name: "autoresponderKeyword",
  initialState: INITIAL_STATE,
  reducers: {
    fetchAutoresponderKeywordData: (state) => {
      state.autoresponderKeyword.isLoading = true;
    },
    fetchAutoresponderKeywordSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      autoresponderKeyword: {
        ...state.autoresponderKeyword,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchAutoresponderKeywordFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      autoresponderKeyword: {
        ...state.autoresponderKeyword,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearKeywords: (state) => ({
      ...state,
      autoresponderKeyword: {
        ...state.autoresponderKeyword,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: [],
      },
    }),
  },
});

export const {
  fetchAutoresponderKeywordData,
  fetchAutoresponderKeywordFailure,
  fetchAutoresponderKeywordSuccess,
  clearKeywords,
} = AutoresponderKeywordSlice.actions;
export const AutoresponderKeywordReducer = AutoresponderKeywordSlice.reducer;
