import { createSelector } from "reselect";

const templateSelector = (state: TReduxState) => state.features.template;

export const getBaseUrl = createSelector(templateSelector, (app) => app.baseUrl);

export const getTemplateData = createSelector(
  templateSelector,
  (app) => app.apiStatus.data
);

export const getTemplateState = createSelector(templateSelector, (app) => app.note);

export const getTemplateLoading = createSelector(
  getTemplateState,
  (states) => states?.isLoading
);
