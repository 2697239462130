import { RequestTypes } from "../types";

class RequestAppAction {
  static userSignOut(data: any) {
    return {
      type: RequestTypes.SIGN_OUT_REQUEST,
      payload: data,
    };
  }
  static fetchDid(data?: any) {
    return {
      type: RequestTypes.GET_DIDS_REQUEST,
      payload: data,
    };
  }
  static fetchConversations(data?: any) {
    return {
      type: RequestTypes.GET_CONVERSATIONS_REQUEST,
      payload: data,
    };
  }
  static fetchConversationsById(data?: any) {
    return {
      type: RequestTypes.GET_CONVERSATIONS_ID_REQUEST,
      payload: data,
    };
  }
  static fetchContacts(data?: any) {
    return {
      type: RequestTypes.GET_CONTACT_REQUEST,
      payload: data,
    };
  }
  static fetchNotes(data?: any) {
    return {
      type: RequestTypes.GET_NOTES_REQUEST,
      payload: data,
    };
  }
  static postNotes(data?: any) {
    return {
      type: RequestTypes.POST_NOTE_REQUEST,
      payload: data,
    };
  }
  static getUser(data?: any) {
    return {
      type: RequestTypes.GET_USER_REQUEST,
      payload: data,
    };
  }
  static putNote(data?: any) {
    return {
      type: RequestTypes.PUT_NOTE_REQUEST,
      payload: data,
    };
  }
  static deleteNote(data?: any) {
    return {
      type: RequestTypes.DELETE_NOTE_REQUEST,
      payload: data,
    };
  }
  static fetchScheduleMessage(data?: any) {
    return {
      type: RequestTypes.GET_SCHEDULE_MSG_REQUEST,
      payload: data,
    };
  }
  static postScheduleMessage(data?: any) {
    return {
      type: RequestTypes.POST_SCHEDULE_MSG_REQUEST,
      payload: data,
    };
  }
  static deleteSchdeuleMessage(data?: any) {
    return {
      type: RequestTypes.DELETE_SCHEDULE_MSG_REQUEST,
      payload: data,
    };
  }
  static putScheduleMessage(data?: any) {
    return {
      type: RequestTypes.PUT_SCHEDULE_MSG_REQUEST,
      payload: data,
    };
  }
  static fetchTemplate(data?: any) {
    return {
      type: RequestTypes.GET_TEMPLATE_REQUEST,
      payload: data,
    };
  }
  static postTemplate(data?: any) {
    return {
      type: RequestTypes.POST_TEMPLATE_REQUEST,
      payload: data,
    };
  }
  static deleteTemplate(data?: any) {
    return {
      type: RequestTypes.DELETE_TEMPLATE_REQUEST,
      payload: data,
    };
  }
  static putTemplate(data?: any) {
    return {
      type: RequestTypes.PUT_TEMPLATE_REQUEST,
      payload: data,
    };
  }
  static fetchPreference(data?: any) {
    return {
      type: RequestTypes.GET_PREFERENCE_REQUEST,
      payload: data,
    };
  }
  static postPreference(data?: any) {
    return {
      type: RequestTypes.POST_PREFERENCE_REQUEST,
      payload: data,
    };
  }
  static postforgetPassword(data?: any) {
    return {
      type: RequestTypes.POST_FORGET_PASSWORD,
      payload: data,
    };
  }
  static verifyToken(payload?: any) {
    return {
      type: RequestTypes.POST_VERIFY_TOKEN,
      payload,
    };
  }
  static resetPassword(payload?: any) {
    return {
      type: RequestTypes.POST_RESET_PASSWORD,
      payload,
    };
  }
  static changePassword(payload?: any) {
    return {
      type: RequestTypes.PUT_CHANGE_PASSWORD,
      payload,
    };
  }
  static postHolidayResponder(payload?: any) {
    return {
      type: RequestTypes.POST_HOLIDAY_AUTORESPONDER,
      payload,
    };
  }
  static getHolidayResponder(payload?: any) {
    return {
      type: RequestTypes.GET_HOLIDAY_AUTORESPONDER,
      payload,
    };
  }
  static updateHolidayResponder(payload?: any) {
    return {
      type: RequestTypes.UPDATE_HOLIDAY_AUTORESPONDER,
      payload,
    };
  }
  static deleteHolidayResponder(payload?: any) {
    return {
      type: RequestTypes.DELETE_HOLIDAY_AUTORESPONDER,
      payload,
    };
  }
  static getAutoResponder(payload?: any) {
    return {
      type: RequestTypes.GET_AUTORESPONDER_REQUEST,
      payload,
    };
  }
  static putAutoResponder(payload?: any) {
    return {
      type: RequestTypes.PUT_AUTORESPONDER_REQUEST,
      payload,
    };
  }
  static postWorkingDays(payload?: any) {
    return {
      type: RequestTypes.POST_WORKING_DAYS_REQUEST,
      payload,
    };
  }
  static getWorkingDays(payload?: any) {
    return {
      type: RequestTypes.GET_WORKING_DAYS_REQUEST,
      payload,
    };
  }
  static putWorkingDays(payload?: any) {
    return {
      type: RequestTypes.PUT_WORKING_DAYS_REQUEST,
      payload,
    };
  }
  static deleteWorkingDays(payload?: any) {
    return {
      type: RequestTypes.DELETE_WORKING_DAYS_REQUEST,
      payload,
    };
  }
  static postAutoresponderKeyword(payload?: any) {
    return {
      type: RequestTypes.POST_AUTORESPONDER_KEYWORD_REQUEST,
      payload,
    };
  }
  static putAutoresponderKeyword(payload?: any) {
    return {
      type: RequestTypes.PUT_AUTORESPONDER_KEYWORD_REQUEST,
      payload,
    };
  }
  static fetchAutoresponderKeyword(payload?: any) {
    return {
      type: RequestTypes.GET_AUTORESPONDER_KEYWORD_REQUEST,
      payload,
    };
  }
  static deleteAutoresponderKeyword(payload?: any) {
    return {
      type: RequestTypes.DELETE_AUTORESPONDER_KEYWORD_REQUEST,
      payload,
    };
  }
  static editUser(payload?: any) {
    return {
      type: RequestTypes.PUT_USER_REQUEST,
      payload,
    };
  }
  static sendMessageRequest(data?: any) {
    return {
      type: RequestTypes.SEND_MESSAGE_REQUEST,
      payload: data,
    };
  }
  static addNewContact(data?: any) {
    return {
      type: RequestTypes.NEW_CONTACT_REQUEST,
      payload: data,
    };
  }
  static postMultipleMessage(data?: any) {
    return {
      type: RequestTypes.POST_MULTIPLE_MESSAGE_REQUEST,
      payload: data,
    };
  }
  static uploadContacts(data?: any) {
    return {
      type: RequestTypes.IMPORT_CONTACT_REQUEST,
      payload: data,
    };
  }
  static downloadContacts(data?: any) {
    return {
      type: RequestTypes.EXPORT_CONTACT_REQUEST,
      payload: data,
    };
  }
  static fetchGroupList(data?: any) {
    return {
      type: RequestTypes.GET_GROUP_LIST_REQUEST,
      payload: data,
    };
  }
  static fetchGroupContacts(data?: any) {
    return {
      type: RequestTypes.GET_GROUP_CONTACTS_REQUEST,
      payload: data,
    };
  }
  static fetchGroupMessages(data?: any) {
    return {
      type: RequestTypes.GROUP_MESSAGES_REQUEST,
      payload: data,
    };
  }
  static postGroupMessage(data?: any) {
    return {
      type: RequestTypes.POST_GROUP_MESSAGE_REUQEST,
      payload: data,
    };
  }
  static addGroupContacts(data?: any) {
    return {
      type: RequestTypes.ADD_CONTACT_GROUP_REQUEST,
      payload: data,
    };
  }
  static createGroup(data?: any) {
    return {
      type: RequestTypes.CREATE_GROUP_REQUEST,
      payload: data,
    };
  }
  static updateGroupName(data?: any) {
    return {
      type: RequestTypes.UPDATE_GROUP_NAME,
      payload: data,
    };
  }
  static deleteGroupContact(data?: any) {
    return {
      type: RequestTypes.DELETE_GROUP_CONTACT,
      payload: data,
    };
  }
  static deleteContact(data?: any) {
    return {
      type: RequestTypes.DELETE_CONTACT_REQUEST,
      payload: data,
    };
  }
  static deleteConversation(data?: any) {
    return {
      type: RequestTypes.DELETE_CONVERSATION_REQUEST,
      payload: data,
    };
  }
  static deleteGroup(data?: any) {
    return {
      type: RequestTypes.DELETE_GROUP_REQUEST,
      payload: data,
    };
  }
  static deleteBulkContacts(data?: any) {
    return {
      type: RequestTypes.DELETE_BULK_CONTACTS,
      payload: data,
    };
  }
  static deleteBulkConversations(data?: any) {
    return {
      type: RequestTypes.DELETE_BULK_CONVERSATION,
      payload: data,
    };
  }
  static uploadFiles(data?: any) {
    return {
      type: RequestTypes.UPLOADFILES_REQUEST,
      payload: data,
    };
  }
  static bulkDeleteGroup(data?: any) {
    return {
      type: RequestTypes.DELETE_BULK_GROUPS,
      payload: data,
    };
  }
  static fetchEvent(data?: any) {
    return {
      type: RequestTypes.GET_EVENT_REQUEST,
      payload: data,
    };
  }
  static postEvent(data?: any) {
    return {
      type: RequestTypes.POST_EVENT_REQUEST,
      payload: data,
    };
  }
  static deleteEvent(data?: any) {
    return {
      type: RequestTypes.DELETE_EVENT_REQUEST,
      payload: data,
    };
  }
  static putEvent(data?: any) {
    return {
      type: RequestTypes.PUT_EVENT_REQUEST,
      payload: data,
    };
  }
  static downloadGroupMessages(data?: any) {
    return {
      type: RequestTypes.DOWNLOAD_GROUP_MESSAGES,
      payload: data,
    };
  }
  static downloadConversation(data?: any) {
    return {
      type: RequestTypes.EXPORT_CONVERSATION_REQUEST,
      payload: data,
    };
  }
  static getLoggedInUser(data?: any) {
    return {
      type: RequestTypes.GET_LOGGEDIN_USER_REQUEST,
      payload: data,
    };
  }
  static postBulkScheduleMessage(data?: any) {
    return {
      type: RequestTypes.POST_BULK_SCHEDULE_MSG_REQUEST,
      payload: data,
    };
  }
  static fetchBulkContacts(data?: any) {
    return {
      type: RequestTypes.BULK_MESSAGE_CONTACTS,
      payload: data,
    };
  }
  static fetchPlans(data?: any) {
    return {
      type: RequestTypes.PLANS_REQUEST,
      payload: data,
    };
  }
  static fetchDidNotification(data?: any) {
    return {
      type: RequestTypes.DID_NOTIFICATION_REQUEST,
      payload: data,
    };
  }
}

export default RequestAppAction;
