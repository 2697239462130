import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  putTemplateFailure,
  putTemplateSuccess,
} from "../slices/features/postTemplate";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* putTemplateSaga(action: any) {
  const { payload } = action;
  const { id, data } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.putTemplate,
      baseUrl,
      id,
      data
    );

    yield put(putTemplateSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(putTemplateFailure({ statusCode, statusText }));
  }
}

export function* watchPutTemplate() {
  yield takeLatest(RequestTypes.PUT_TEMPLATE_REQUEST, putTemplateSaga);
}
