import { CloseCircleFilled } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Menu,
  MenuProps,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
  notification,
} from "antd";
import en from "antd/es/date-picker/locale/en_US";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PAGINATION, maxSize } from "src/constants/common";
import { returnElipsesText } from "src/constants/functions";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import { getTemplateData } from "src/store/selectors/features/template";
import { uploadFilesLoading } from "src/store/selectors/features/uploadFiles";
import RequestAppAction from "src/store/slices/appActions";
import Colors from "src/themes/colors";
import Button from "../button";
import { TextifyModal } from "../modal";
import AddVariableBtn from "../modal-button-container/add-variable/AddVariableBtn";
import ModalButtonContainer from "../modal-button-container";
import { replaceContentVariables } from "../modal-button-container/add-variable/utils";
import AttachmentBtn from "../modal-button-container/AttachmentBtn";
import TemplateBtn from "../modal-button-container/TemplateBtn";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { firstName } from "src/constants/add-variable";

dayjs.extend(buddhistEra);

const buddhistLocale = {
  ...en,
  lang: {
    ...en.lang,
    fieldDateFormat: "YYYY-MM-DD",
    fieldDateTimeFormat: "YYYY-MM-DD h:mm",
    yearFormat: "YYYY",
    cellYearFormat: "YYYY",
  },
} as typeof en;

interface props {
  images?: string[];
  modalRef: any;
  contactId: string | number | null;
  form: any;
  isEdit?: boolean;
  setPagination?: any;
  fetchNewData?: () => void;
  templateModalRef?: any;
  setImages?: (param: []) => void;
  variableData?: any;
}

export const AddScheduleMessageModal = forwardRef(
  (
    {
      images,
      modalRef,
      contactId,
      form,
      isEdit,
      setPagination,
      fetchNewData,
      setImages,
      templateModalRef,
      // variableData,
    }: props,
    ref
  ) => {
    const dispatch = useDispatch();
    const { Text } = Typography;
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const textareaRef: any = useRef<any>(null);
    const uploadFileRef: any = useRef<HTMLInputElement | null>(null);
    const templates: any = useSelector(getTemplateData);
    const [units, setUnits] = useState<number>(0);
    const [characters, setCharacters] = useState<number>(0);
    const [totalCharacters, setTotalCharacters] = useState<String>("");
    const [message, setMessage] = useState<string>("");
    const [variableList, setVariableList] = useState<any>([]);
    const [attachments, setAttachments] = useState<any>([]);
    const spinning = useSelector(uploadFilesLoading);
    const did = useSelector(getCurrentDidId);

    useImperativeHandle(ref, () => ({
      closeModal: () => {
        form.resetFields();
        setCharacters(0);
        setUnits(0);
        modalRef.current?.closeModal();
      },
    }));

    useEffect(() => {
      if (isEdit) {
        const content = form.getFieldValue("content");
        setTotalCharacters(content);
        setMessage(content);
      }
    }, [form, isEdit]);

    useEffect(() => {
      if (images) {
        setAttachments(images ?? []);
      }
    }, [images]);

    useEffect(() => {
      const variables = [];
      variables.push(firstName);
      setVariableList(variables);
    }, []);

    useEffect(() => {
      const charactersLength = totalCharacters?.length ?? 0;
      const totalUnits = Math.floor(charactersLength / 160);
      const chr = charactersLength - (totalUnits || 0) * 160;

      if (charactersLength === 0 && attachments?.length === 0) {
        setUnits(0);
      } else if (charactersLength > 0) {
        let val = totalUnits + 1 + attachments?.length * 3;
        setUnits(val);
      } else {
        let val = attachments?.length * 3;
        setUnits(val);
      }

      setCharacters(chr);
    }, [totalCharacters, attachments]);

    const onTextChange = (value: string) => {
      setMessage(value);
      setTotalCharacters(value.trim());
    }

    const handleTextChange = async (e: any) => {
      onTextChange(e.target.value);
    };

    const handleCancel = () => {
      form.resetFields();
      setTotalCharacters("");
      setMessage("");
      setAttachments([]);
      setCharacters(0);
      setUnits(0);
      if (setImages) {
        setImages([]);
      }

      modalRef.current?.closeModal();
    };

    const onSave = async (values: any, id: any) => {
      try {
        await form.validateFields();
        const scheduledTime = new Date(values.scheduledTime);
        const data: {
          content: string;
          scheduledTime: string;
          fileLocations?: any[];
          variableList?: any[];
        } = {
          content: replaceContentVariables(values.content, variableList),
          scheduledTime: scheduledTime.toISOString(),
          variableList: variableList,
        };
        if (attachments.length > 0) {
          data["fileLocations"] = attachments;
        }
        dispatch(
          RequestAppAction.postScheduleMessage({
            data: data,
            id: id,
            cbSuccess: () => {
              notification.success({
                message: t("notification.saveScheduledSuccess"),
              });
              handleCancel();
              setAttachments([]);
              if (setImages) {
                setImages([]);
              }
              dispatch(
                RequestAppAction.fetchContacts({
                  id: did,
                  meta: PAGINATION,
                })
              );
              dispatch(
                RequestAppAction.fetchConversations({
                  id: did,
                  meta: PAGINATION,
                  cbFailure: (e: string) => {
                    notification.error({ message: e });
                  },
                })
              );

              if (fetchNewData) {
                fetchNewData();
              } else {
                dispatch(
                  RequestAppAction.fetchScheduleMessage({
                    id: contactId,
                    meta: PAGINATION,
                    cbSuccess: () => {
                      setPagination({ ...PAGINATION });
                    },
                  })
                );
                setPagination({ ...PAGINATION });
              }
            },
          })
        );
      } catch (errorInfo: any) {
        notification.error({ message: t("notification.saveScheduledFail") });
        notification.error({ message: errorInfo });
      }
    };

    const onUpdate = async (values: any, id: any) => {
      try {
        await form.validateFields();
        const scheduledTime = new Date(values.scheduledTime);
        const data: {
          scheduledTime: string;
          content: string;
          fileLocations?: string[];
          variableList?: any[];
        } = {
          content: replaceContentVariables(values.content, variableList),
          scheduledTime: scheduledTime.toISOString(),
          fileLocations: attachments,
          variableList: variableList,
        };

        dispatch(
          RequestAppAction.putScheduleMessage({
            data: data,
            id: id,
            cbSuccess: () => {
              notification.success({
                message: t("notification.updateScheduledSuccess"),
              });
              setAttachments([]);

              if (setImages) {
                setImages([]);
              }

              if (fetchNewData) {
                fetchNewData();
              } else {
                dispatch(
                  RequestAppAction.fetchScheduleMessage({
                    id: contactId,
                    meta: PAGINATION,
                    cbSuccess: () => {
                      setPagination({ ...PAGINATION });
                    },
                  })
                );
                setPagination({ ...PAGINATION });
              }
              handleCancel();
            },
          })
        );
      } catch (errorInfo: any) {
        if (setImages) {
          setImages([]);
        }
        notification.error({ message: t("notification.updateScheduledFail") });
        notification.error({ message: errorInfo });
      }
    };

    const range = (start: any, end: any) =>
      Array.from({ length: end - start }, (_, i) => start + i);

    const disabledDateTime = (current: any) => {
      const currentDayjs = dayjs();

      const isToday = dayjs().date();

      return {
        disabledHours: () => (isToday ? range(0, currentDayjs?.hour()) : []),
        disabledMinutes: () =>
          isToday && currentDayjs?.hour() === current?.hour()
            ? range(0, currentDayjs.minute() + 1)
            : [],
      };
    };

    const handleMenuItemClick = (item: any) => {
      if (item.key === "Add New Template") {
        templateModalRef.current?.openModal(t("modalHeading.createNewTemplate"));
      } else {
        form.setFieldsValue({
          content: item.content,
        });
      }
    };

    const menuItems: MenuProps["items"] = templates?.items?.map(
      (template: { content: any; title: string }, index: any) => ({
        key: String(index),
        label: template.title,
        content: template?.content,
      })
    );
    menuItems?.push({ key: "Add New Template", label: "Add New Template" });

    const menu = () => (
      <Menu>
        {menuItems?.map((menuItem: any, index: number) => (
          <Menu.Item onClick={() => handleMenuItemClick(menuItem)} key={index}>
            {menuItem.label}
          </Menu.Item>
        ))}
      </Menu>
    );

    const upload = (e: any) => {
      const formData = new FormData();
      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > maxSize) {
          // Handle error: File size exceeds 5MB
          notification.error({
            message: t("common.fileSizeError", { name: file.name }),
          });

          return;
        }
      }

      for (const file of files) {
        formData.append("files", file);
      }

      dispatch(
        RequestAppAction.uploadFiles({
          data: formData,
          cbSuccess: (res: any) => {
            uploadFileRef.current.value = "";
            setAttachments((pre: any) => [...pre, ...res?.data]);
          },
          cbFailure: (e: string) => {
            uploadFileRef.current.value = "";
            notification.error({ message: e });
          },
        })
      );
    };

    const defaultDatePickerValue =
      isEdit && dayjs(form.getFieldValue("scheduledTime"));

    return (
      <div style={{ zIndex: 9999 }}>
        <TextifyModal ref={modalRef} afterClose={handleCancel}>
          <input
            ref={uploadFileRef}
            onChange={(e) => {
              upload(e);
            }}
            style={{ display: "none" }}
            type="file"
            multiple
          />
          <Spin spinning={spinning}>
            <Flex vertical>
              <Spin spinning={false}>
                <Form
                  onFinish={(values) =>
                    isEdit
                      ? onUpdate(values, form.getFieldValue("id"))
                      : onSave(values, contactId)
                  }
                  form={form}
                  name="scheduleForm"
                >
                  <div className="p-5">
                    <div className="d-flex justify-content-between align-items-center pt-5">
                      <Form.Item
                        name="scheduledTime"
                        rules={[
                          { required: true, message: "Please Select Date" },
                        ]}
                      >
                        <DatePicker
                          defaultValue={defaultDatePickerValue || undefined}
                          showTime
                          disabledDate={(curr) =>
                            curr.isBefore(dayjs().subtract(1, "day"))
                          }
                          disabledTime={disabledDateTime}
                          locale={buddhistLocale}
                          use12Hours
                          format="YYYY-MM-DD h:mm A"
                          placeholder="Select Date and Time"
                        />
                      </Form.Item>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-5">
                      <Text className="font-size-16">
                        {t("sideBar.message")}
                      </Text>
                      <Text className="">
                        <div>
                          {t("sideBar.chars")} {characters}/160
                          <span className="pl-5">
                            {t("sideBar.msgUnits")} {units}/10
                          </span>
                        </div>
                      </Text>
                    </div>
                    <div
                      className="align-items-end pb-5"
                      style={{ position: "relative" }}
                    >
                      <Form.Item
                        name="content"
                        rules={[
                          // {
                          //   required: true,
                          //   message: "Please add some content",
                          // },
                          {
                            validator: (_, value) => {
                              if (!value && (!attachments || attachments.length === 0)) {
                                return Promise.reject(
                                  "Please add some content"
                                );
                              }
                              if (value && value.length > 1600) {
                                return Promise.reject(
                                  "Content length cannot exceed 1600 characters"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input.TextArea
                          ref={textareaRef}
                          style={{ resize: "none" }}
                          rows={8}
                          placeholder="Type your text here..."
                          onChange={handleTextChange}
                          autoFocus
                        />
                      </Form.Item>
                      <ModalButtonContainer>
                        {width > 576 && (
                          <AddVariableBtn
                            disabled={!variableList || variableList.length === 0}
                            variableList={variableList}
                            form={form}
                            textareaRef={textareaRef}
                            message={message}
                            onTextChange={onTextChange}
                            style={{ borderRadius: 50, marginLeft: 10 }}
                          />
                        )}
                        <AttachmentBtn
                          uploadFileRef={uploadFileRef}
                          style={{ borderRadius: 50, marginLeft: 10 }}
                        />
                        <TemplateBtn
                          templateMenu={menu}
                          style={{ borderRadius: 50, marginLeft: 10 }}
                        />
                      </ModalButtonContainer>
                    </div>
                  </div>
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                      }}
                      span={24}
                    >
                      {attachments.map((i: string, ind: number) => (
                        <div
                          style={{
                            position: "relative",
                            marginRight: "0.5rem",
                          }}
                        >
                          <Tag
                            onClick={() => {
                              window.open(i, "_blank");
                            }}
                            style={{
                              background: Colors.white,
                              borderRadius: 4,
                            }}
                          >
                            <Text
                              key={ind}
                              className="font-size-14"
                              style={{
                                display: "flex",
                                cursor: "pointer  ",
                              }}
                            >
                              {returnElipsesText(i)}
                            </Text>
                          </Tag>
                          <CloseCircleFilled
                            onClick={() => {
                              const arr = attachments;
                              setAttachments(
                                arr.filter((item: any) => item !== i)
                              );
                            }}
                            style={{
                              color: Colors.red,
                              position: "absolute",
                              top: -10,
                              right: 0,
                              zIndex: 2,
                            }}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      padding: "10px",
                    }}
                  >
                    <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                      <Button
                        onBtnClick={handleCancel}
                        title={"Cancel"}
                        btnClass="white_btn"
                      />
                    </div>
                    <div>
                      {units > 10 ? (
                        <Tooltip
                          open
                          trigger={"hover"}
                          color="red"
                          title={t("message.unitError")}
                        >
                          <Button
                            title={"Save"}
                            disabled={true}
                            buttonType="button"
                          />
                        </Tooltip>
                      ) : (
                        <Button title={"Save"} buttonType="submit" />
                      )}
                    </div>
                  </div>
                </Form>
              </Spin>
            </Flex>
          </Spin>
        </TextifyModal>
      </div>
    );
  }
);
