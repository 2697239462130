import { GlobalOutlined, MailOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreferenceData,
  getPreferenceLoading,
} from "src/store/selectors/features/preference";
import RequestAppAction from "src/store/slices/appActions";
import Colors from "src/themes/colors";
import SettingsLayout from "src/layouts/settings";
import "../setting.scss";

const { Title, Text } = Typography;

const Preferences: React.FC = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const preferencesData = useSelector(getPreferenceData);
  const [settings, setSettings] = useState(null);
  const timeZoneList = moment.tz.names();
  const isLoading = useSelector(getPreferenceLoading);

  const {
    emailNotification,
    audioNotification,
    pushNotification,
    vibrateNotification,
    email,
    additionalEmail,
    timeZone,
  }: any = settings || {};

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      RequestAppAction.fetchPreference({
        cbFailure: (e: string) => {
          notification.error({ message: e });
        },
      })
    );
  }, []);

  useEffect(() => {
    if (Array.isArray(preferencesData)) {
      setSettings(preferencesData[0]);
      form.setFieldsValue({ ...preferencesData[0] });
    } else {
      setSettings(preferencesData);
      form.setFieldsValue({ ...preferencesData });
    }

    form.setFieldsValue({ ...preferencesData });
  }, [preferencesData]);

  const customLabel = (zone: any) => (
    <div>
      <span style={{ marginRight: "0.5rem" }}>
        {" "}
        <GlobalOutlined /> {t("prefrences.timeZone")} | {zone}
      </span>
      {zone}
    </div>
  );

  const handleSave = (values: any) => {
    const data = {
      emailNotification: values.emailNotification,
      audioNotification: values.audioNotification,
      email: values.email?.trim(),
      additionalEmail: values.additionalEmail?.trim(),
      timeZone: values.timeZone || "",
      pushNotification: pushNotification,
      vibrateNotification: vibrateNotification,
    };

    dispatch(
      RequestAppAction.postPreference({
        data: data,
        cbSuccess: () => {
          notification.success({
            message: t("notification.updatePreference"),
          });
          dispatch(
            RequestAppAction.fetchPreference({
              cbFailure: (e: string) => {
                notification.error({ message: e });
              },
            })
          );
        },
      })
    );
  };

  return (
    <SettingsLayout classname="preference-page">
      <Spin spinning={isLoading}>
        <Form
          form={form}
          initialValues={{
            emailNotification: emailNotification,
            audioNotification: audioNotification,
            email: email,
            additionalEmail: additionalEmail,
            timeZone: timeZone,
          }}
          onFinish={(values) => handleSave(values)}
        >
          <Space style={{ width: "100%" }} direction="vertical" size={10}>
            <Row className="d-flex justify-content-center">
              <Col span={24} className="d-flex justify-content-left">
                <Title level={4}>{t("heading.prefrences")}</Title>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col
                span={24}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <Text>{t("heading.emailNotifications")}</Text>
                </div>
                <Form.Item name="emailNotification" valuePropName="checked">
                  <Switch
                    loading={isLoading}
                    checkedChildren={t("switch.on")}
                    unCheckedChildren={t("switch.off")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col
                span={24}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <Text> {t("heading.audioNotifications")} </Text>
                </div>
                <Form.Item name="audioNotification" valuePropName="checked">
                  <Switch
                    loading={isLoading}
                    checkedChildren={t("switch.on")}
                    unCheckedChildren={t("switch.off")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col span={24}>
                <Form.Item
                  style={{
                    marginBottom: 30,
                  }}
                  name="email"
                  rules={[
                    { type: "email", message: t("fields.emailError") },
                  ]}
                >
                  <Input
                    placeholder=""
                    allowClear
                    disabled
                    prefix={
                      <>
                        <MailOutlined />
                        <span
                          style={{
                            marginLeft: "0.1rem",
                            marginRight: "0.5rem",
                          }}
                        >
                          {t("placeholder.primaryEmailAddress")}
                        </span>
                        <div style={{ marginRight: "0.5rem" }}>
                          {"  "} |
                        </div>
                      </>
                    }
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 30 }}
                  name="additionalEmail"
                  rules={[
                    { type: "email", message: t("fields.emailError") },
                  ]}
                >
                  <Input
                    placeholder=""
                    allowClear
                    prefix={
                      <>
                        <MailOutlined />
                        <span
                          style={{
                            marginLeft: "0.1rem",
                            marginRight: "0.5rem",
                          }}
                        >
                          {t("placeholder.additionalEmailAddress")}
                        </span>
                        <div style={{ marginRight: "0.5rem" }}>
                          {"  "} |
                        </div>
                      </>
                    }
                  />
                </Form.Item>
                <Form.Item name="timeZone">
                  <Select
                    suffixIcon={
                      <>
                        <div style={{ color: Colors.Black }}>{"  "} |</div>
                        <span
                          style={{
                            marginLeft: "0.6rem",
                            marginRight: "0rem",
                            color: Colors.Black,
                          }}
                        >
                          {t("prefrences.timeZone")}{" "}
                        </span>
                        <GlobalOutlined style={{ color: "black" }} />
                      </>
                    }
                    placeholder="Select time zone"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      const children = option?.props?.children;
                      const str = children?.props.children;
                      return (
                        str.toLowerCase().includes(input?.toLowerCase()) > 0
                      );
                    }}
                    style={{ width: "100%", direction: "rtl" }}
                    dropdownRender={(menu) => <div>{menu}</div>}
                  >
                    {timeZoneList.map((zone) => (
                      <Select.Option
                        key={zone}
                        value={zone}
                        label={customLabel(zone)}
                      >
                        <Text
                          style={{
                            textAlign: "end",
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          {zone}
                        </Text>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col span={24} className="d-flex justify-content-left">
                <Space>
                  <Button type="primary" htmlType="submit">
                    {" "}
                    {t("button.save")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Space>
        </Form>
      </Spin>
    </SettingsLayout>
  );
};

export default Preferences;
