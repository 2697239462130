import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  note: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: {
      items: [],
    },
  },
};

export const NotesSlice = createSlice({
  name: "notes",
  initialState: INITIAL_STATE,
  reducers: {
    fetchNotesData: (state) => {
      state.note.isLoading = true;
    },
    fetchNotesSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      note: {
        ...state.note,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchNotesFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      note: {
        ...state.note,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearNotes: (state) => ({
      ...state,
      note: {
        ...state.note,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: {
          items: [],
        },
      },
    }),
  },
});

export const {
  fetchNotesData,
  fetchNotesFailure,
  fetchNotesSuccess,
  clearNotes,
} = NotesSlice.actions;
export const NotesReducer = NotesSlice.reducer;
