// import { HttpService } from '../http';
// import { prepareErrorResponse, prepareResponseObject } from '../http/response';
// import { RESPONSE_TYPES } from '../../constants/response-types';

// export class AppService extends HttpService {
//   fetchAppData = async (baseAuthUrl: string): Promise<any>  => {
//     try {
//       // Example of an API call to fetch the app-data
//       // This would be consumed in an async action
//       const apiResponse = await this.post(`${baseAuthUrl}app`,
//         undefined );

//       return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
//     } catch (error) {
//       throw prepareErrorResponse(error);
//     }
//   };
// }

import ENDPOINTS from "src/constants/endpoints";
import { RESPONSE_TYPES } from "../../constants/response-types";
import { HttpService } from "../http";
import { prepareErrorResponse, prepareResponseObject } from "../http/response";

export class AppService extends HttpService {
  fetchAppData = async (baseAuthUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.post(`${baseAuthUrl}app`, undefined);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchUserDetails = async (baseApiUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.get(baseApiUrl + ENDPOINTS.FETCHDIDS);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postSignout = async (baseApiUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.SIGNOUT,
        undefined,
        {
          headers: { application: "web" },
        }
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };

  putChangePassword = async (baseApiUrl: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.patch(
        baseApiUrl + ENDPOINTS.UPDATEPASSWORD,
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchConversations = async (
    baseApiUrl: string,
    id: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.FETCHCONVERSATIONS(id, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchConversationsById = async (
    baseApiUrl: string,
    id: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.FETCHCONVERSATIONSBYID(id, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchContacts = async (
    baseApiUrl: string,
    id: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.FETCHCONTACTS(id, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchNotes = async (
    baseApiUrl: string,
    id: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.FETCHNOTES(id, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postNotes = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.POSTNOTES(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchUser = async (baseApiUrl: string, id: string): Promise<any> => {
    try {
      const apiResponse = await this.get(baseApiUrl + ENDPOINTS.FETCHUSER(id));

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  putNotes = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseApiUrl + ENDPOINTS.EDITNOTE(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteNotes = async (baseApiUrl: string, id: string): Promise<any> => {
    try {
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.EDITNOTE(id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchScheduleMessage = async (
    baseApiUrl: string,
    id: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.FETCHSCHEDULEMESSAGE(id, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postScheduleMessage = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.POSTSCHEDULEMESSAGE(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteScheduleMessage = async (
    baseApiUrl: string,
    id: string
  ): Promise<any> => {
    try {
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.EDITSCHEDULEMESSAGE(id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  putScheduleMessage = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseApiUrl + ENDPOINTS.EDITSCHEDULEMESSAGE(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchTemplate = async (
    baseApiUrl: string,
    id: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.FETCHTEMPLATE(id, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postTemplate = async (
    baseApiUrl: string,
    id: string,
    data: any,
    meta?: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();

      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.POSTTEMPLATE(id, queryString),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  putTemplate = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseApiUrl + ENDPOINTS.EDITTEMPLATE(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteTemplate = async (baseApiUrl: string, id: string): Promise<any> => {
    try {
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.EDITTEMPLATE(id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchPreference = async (baseApiUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.FETCHPREFERENCE
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postPreference = async (baseApiUrl: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.FETCHPREFERENCE,
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postForgetPassword = async (baseApiUrl: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.FORGETPASSWORD,
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postVerifyToken = async (
    baseUrl: string,
    userId: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseUrl + ENDPOINTS.VERIFYTOKEN(userId),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  resetPassword = async (
    baseUrl: string,
    data: any,
    userId: string
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseUrl + ENDPOINTS.RESETPASSWORD(userId),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postHolidayResponder = async (
    baseUrl: string,
    data: any,
    id: string | number
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseUrl + ENDPOINTS.HOLIDAYRESPONDER(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  getHolidayResponder = async (
    baseUrl: string,
    id: string | number
  ): Promise<any> => {
    try {
      const apiResponse = await this.get(
        baseUrl + ENDPOINTS.HOLIDAYRESPONDER(id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  updateHolidayResponder = async (
    baseUrl: string,
    id: string | number,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseUrl + ENDPOINTS.UPDATEHOLIDAYRESPONDER(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteHolidayResponder = async (
    baseUrl: string,
    id: string | number
  ): Promise<any> => {
    try {
      const apiResponse = await this.delete(
        baseUrl + ENDPOINTS.UPDATEHOLIDAYRESPONDER(id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  getAutoresponder = async (
    baseUrl: string,
    id: string | number
  ): Promise<any> => {
    try {
      const apiResponse = await this.get(baseUrl + ENDPOINTS.AUTORESPONDER(id));

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  putAutoresponder = async (
    baseUrl: string,
    id: string | number,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseUrl + ENDPOINTS.AUTORESPONDER(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postWorkingDays = async (
    baseUrl: string,
    id: string | number,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseUrl + ENDPOINTS.WORKINGDAYS(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  getWorkingDays = async (
    baseUrl: string,
    id: string | number
  ): Promise<any> => {
    try {
      const apiResponse = await this.get(baseUrl + ENDPOINTS.WORKINGDAYS(id));

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  putWorkingDays = async (
    baseUrl: string,
    id: string | number,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseUrl + ENDPOINTS.PUTWORKINGDAYS(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };

  postAutoresponderKeyword = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.POSTAUTORESPONDERKEYWORD(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteWorkingDays = async (
    baseUrl: string,
    id: string | number
  ): Promise<any> => {
    try {
      const apiResponse = await this.delete(
        baseUrl + ENDPOINTS.PUTWORKINGDAYS(id)
      );
      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };

  putAutoresponderKeyword = async (
    baseUrl: string,
    id: string | number,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseUrl + ENDPOINTS.AUTORESPONDERKEYWORD(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteAutoresponderKeyword = async (
    baseUrl: string,
    id: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.delete(
        baseUrl + ENDPOINTS.AUTORESPONDERKEYWORD(id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchAutoresponderKeyword = async (
    baseUrl: string,
    id: string | number
  ): Promise<any> => {
    try {
      const apiResponse = await this.get(
        baseUrl + ENDPOINTS.POSTAUTORESPONDERKEYWORD(id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  putUser = async (baseApiUrl: string, id: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseApiUrl + ENDPOINTS.FETCHUSER(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postMessage = async (
    baseApiUrl: string,
    id: string,
    did: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.SENDMESSAGE(id, did),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  newContact = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.NEWCONTACT(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postMultipleMessage = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const meta = { didId: id };
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.POSTMULTIPLEMESSAGE(queryString),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  uploadContact = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.UPLOADCONTACT(id),
        data,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  downloadContact = async (baseApiUrl: string, id: number): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.EXPORTCONTACT(id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  getGroups = async (
    baseApiUrl: string,
    id: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.GROUPLIST(id, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  getGroupContacts = async (
    baseApiUrl: string,
    id: string,
    groupId: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.GROUPCONTACTLIST(id, groupId, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  addGroupContacts = async (
    baseApiUrl: string,
    id: string,
    groupId: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.ADDGROUPCONTACT(id, groupId),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  getGroupMessages = async (
    baseApiUrl: string,
    id: string,
    groupId: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.GROUPMESSAGES(id, groupId, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postGroupMessages = async (
    baseApiUrl: string,
    id: string,
    groupId: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.POSTGROUPMESSAGE(id, groupId),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  createGroup = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.CREATEGROUP(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  updateGroupName = async (
    baseApiUrl: string,
    id: string,
    groupId: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseApiUrl + ENDPOINTS.UPDATEGROUPNAME(id, groupId),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteGroupContact = async (
    baseApiUrl: string,
    id: string,
    groupId: string,
    contactId: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.DELETEGROUPCONTACT(id, groupId, contactId)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteConversation = async (
    baseApiUrl: string,
    id: string,
    meta: string
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.DELETECONVERSATION(id, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteContact = async (
    baseApiUrl: string,
    id: string,
    meta: string
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.DELETECONTACT(id, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteGroup = async (
    baseApiUrl: string,
    id: string,
    groupId: string,
    meta: string
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();

      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.DELETEGROUP(id, groupId, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteBulkContact = async (
    baseApiUrl: string,
    data: any[],
    meta: string
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.DELETEBULKCONTACT(queryString),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteBulkConversation = async (
    baseApiUrl: string,
    data: any[],
    meta: string
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.DELETEBULKCONVERSATION(queryString),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  uploadFiles = async (baseApiUrl: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.UPLOADFILES,
        data,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  bulkDeleteGroup = async (
    baseApiUrl: string,
    id: string,
    data: any,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.BULKDELETEGROUP(id, queryString),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchEvents = async (
    baseApiUrl: string,
    id: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta).toString();
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.FETCHEVENTS(id, queryString)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postEvents = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.POSTEVENTS(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  putEvents = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseApiUrl + ENDPOINTS.PUTEVENTS(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteEvents = async (baseApiUrl: string, id: string): Promise<any> => {
    try {
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.PUTEVENTS(id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  downloadGroupMessages = async (
    baseApiUrl: string,
    id: string,
    groupId: string
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.DOWNLOADGROUPMESSAGES(id, groupId)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  downloadConversation = async (
    baseApiUrl: string,
    id: string
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.DOWNLOADCONVERSATION(id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  userDetails = async (baseApiUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.get(baseApiUrl + ENDPOINTS.USERDETAILS);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postBulkScheduleMessage = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.BULKSCHEDULEMESSAGE(id),
        { ...data }
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchPlan = async (baseApiUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.get(baseApiUrl + ENDPOINTS.PLANS);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
}
