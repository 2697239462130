import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

const currentContactUserSlice = createSlice({
  name: "currentContactUser",
  initialState,
  reducers: {
    setContactUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    clearContactUser: (state) => {
      state.user = null;
    },
  },
});

export const { setContactUser, clearContactUser } =
  currentContactUserSlice.actions;
export const ContactUserReducer = currentContactUserSlice.reducer;
