import {  ClockCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const ScheduleBtn = ({
  form,
  setSelectedPhoneNumbers,
  openScheduleMsgModal,
  style = { borderRadius: 50 },
}: {
  form: any;
  setSelectedPhoneNumbers: (numbers: any) => void;
  openScheduleMsgModal: any;
  style?: any;
}) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={() => {
        const numbers = form.getFieldValue("phoneNumber");
        if (numbers) {
          if (Array.isArray(numbers) && numbers?.length > 0) {
            setSelectedPhoneNumbers(numbers);
            openScheduleMsgModal();
          } else {
            setSelectedPhoneNumbers([numbers]);
            if ([numbers]?.length > 0) {
              openScheduleMsgModal();
            }
          }
        }
      }}
      icon={
        <Tooltip title={t("tootlTip.ScheduleMsg")}>
          <ClockCircleOutlined />
        </Tooltip>
      }
      style={style}
    />
  );
};

export default ScheduleBtn;
