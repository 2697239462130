import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchAutoresponderKeywordFailure,
  fetchAutoresponderKeywordSuccess,
} from "../slices/features/autoresponderKeyword";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchAutoresponderKeywordSaga(action: any) {
  const { payload } = action;
  const { id } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchAutoresponderKeyword,
      baseUrl,
      id
    );
    yield put(fetchAutoresponderKeywordSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchAutoresponderKeywordFailure({ statusCode, statusText }));
  }
}

export function* watchfetchAutoresponderKeyword() {
  yield takeLatest(
    RequestTypes.GET_AUTORESPONDER_KEYWORD_REQUEST,
    fetchAutoresponderKeywordSaga
  );
}
