import { createSelector } from "reselect";

const scheduleMessageSelector = (state: TReduxState) => state.features.scheduleMessage;

export const getBaseUrl = createSelector(scheduleMessageSelector, (app) => app.baseUrl);

export const getScheduleMessageData = createSelector(
  scheduleMessageSelector,
  (app) => app.apiStatus.data
);

export const getScheduleMessageState = createSelector(scheduleMessageSelector, (app) => app.note);

export const getScheduleMessageLoading = createSelector(
  getScheduleMessageState,
  (states) => states?.isLoading
);
