import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  messageState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const PostMessageSlice = createSlice({
  name: "message",
  initialState: INITIAL_STATE,
  reducers: {
    PostMessage: (state) => {
      state.messageState.isLoading = true;
    },
    PostMessageSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      messageState: {
        ...state.messageState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    PostMessageFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      messageState: {
        ...state.messageState,
        error: action.payload?.messageState,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const { PostMessage, PostMessageFailure, PostMessageSuccess } =
  PostMessageSlice.actions;
export const PostMessageReducer = PostMessageSlice.reducer;