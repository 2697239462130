import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchChangeGroupNameFailure,
  fetchChangeGroupNameSuccess,
} from "../slices/features/createGroup";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* updateGroupNameSaga(action: any) {
  const { payload } = action;
  const { id, groupId, data } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.updateGroupName,
      baseUrl,
      id,
      groupId,
      data
    );

    yield put(fetchChangeGroupNameSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchChangeGroupNameFailure({ statusCode, statusText }));
  }
}

export function* watchUpdateGroupName() {
  yield takeLatest(RequestTypes.UPDATE_GROUP_NAME, updateGroupNameSaga);
}
