import { PaperClipOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const AttachmentBtn = ({
  uploadFileRef,
  style = { borderRadius: 50, marginLeft: 10 },
}: {
  uploadFileRef?: any;
  style?: any;
}) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={() => uploadFileRef.current?.click()}
      icon={
        <Tooltip title={t("tootlTip.attachFile")}>
          <PaperClipOutlined />
        </Tooltip>
      }
      style={style}
    />
  );
};

export default AttachmentBtn;
