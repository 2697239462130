import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { deleteAutoresponderKeywordSuccess } from "../slices/features/postAutoresponderKeyword";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* deleteAutoresponderKeywordSaga(action: any) {
  const { payload } = action;
  const { id } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.deleteAutoresponderKeyword,
      baseUrl,
      id
    );

    yield put(deleteAutoresponderKeywordSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(deleteAutoresponderKeywordSuccess({ statusCode, statusText }));
  }
}

export function* watchDeleteAutoresponderKeyword() {
  yield takeLatest(RequestTypes.DELETE_AUTORESPONDER_KEYWORD_REQUEST, deleteAutoresponderKeywordSaga);
}