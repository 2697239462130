import {
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  PlusSquareOutlined,
  RetweetOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button as Btn,
  Checkbox,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
  notification,
} from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PAGINATION } from "src/constants/common";
import { getContactUser } from "src/store/selectors/features/contactUser";
import { getContactData } from "src/store/selectors/features/contacts";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import RequestAppAction from "src/store/slices/appActions";
import { clearConversation } from "src/store/slices/features/conversations";
import { setContactUser } from "src/store/slices/features/currentUserContact";
import { setConversationUser } from "src/store/slices/features/currentUserConversation";
import Colors from "src/themes/colors";
import { ConfirmModalPartial } from "./confirmModalPartial";
import "./index.scss";

interface modalProps {
  openModal: (text?: string) => void;
  closeModal: () => void;
}

interface props {
  onPressContact: any;
  onClickImport: () => void;
  openContactModal: () => void;
}

const ListingSidebarContacts = ({
  onPressContact,
  onClickImport,
  openContactModal,
}: props) => {
  const { Text } = Typography;
  const [modalDele, contextHolderDel] = Modal.useModal();
  const scrollRef = useRef(null);
  const [modalEdit, contextHolderEdit] = Modal.useModal();
  const [modalAdd, contextHolderAdd] = Modal.useModal();
  const contacts: any = useSelector(getContactData);
  const did = useSelector(getCurrentDidId);
  const currentUser: any = useSelector(getContactUser);
  const [scrollFetch, setScrollFetch] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState(PAGINATION);
  const [selectedContacts, setSelectedContacts] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [backUp, setBackUp] = useState(false);
  const [items, setItems] = useState<any>(null);
  const [deleteGroup, setDeleteGroup] = useState<{ id: string } | null>(null);
  const [bulkDelete, setBulkDelete] = useState<any[] | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fetchingData, setFetchingData] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const toggleFourthColumn = (itemId: number) => {
    setSelectedItemId((prevId) => (prevId === itemId ? null : itemId));
  };

  const dele = (data: any) => {
    modalDele.confirm({
      centered: true,
      title: t("modal.confirmDelete"),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
            <p>{t("sideBar.sendBackupToEmail")}</p>
            <div>
              <Switch
                onChange={(e) => {
                  setBackUp(e);
                }}
                checkedChildren={t("switch.on")}
                unCheckedChildren={t("switch.off")}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center pb-3"></div>
        </div>
      ),
      okText: t("button.delete"),
      cancelText: t("button.cancel"),
      style: { borderRadius: 10 },
      onOk: () => {
        setDeleteGroup(data);
      },
    });
  };

  useEffect(() => {
    if (bulkDelete && bulkDelete.length > 0) {
      dispatch(
        RequestAppAction.deleteBulkContacts({
          data: { contactId: selectedContacts },
          meta: { backup: backUp },
          cbSuccess: (res: any) => {
            setBulkDelete(null);
            setSelectedContacts([]);
            setBackUp(false);
            dispatch(setContactUser(null));
            dispatch(setConversationUser(null));
            dispatch(clearConversation());

            setFirstLoad(true);
            notification.success({ message: res?.data?.message });
            fetchNewData();
          },
          cbFailure: (e: string) => {
            setBulkDelete(null);
            setBackUp(false);
            notification.error({ message: e });
          },
        })
      );
    }
  }, [backUp, bulkDelete]);

  useEffect(() => {
    if (deleteGroup) {
      dispatch(
        RequestAppAction.deleteContact({
          id: deleteGroup?.id,
          meta: backUp ? { backup: backUp } : "",
          cbSuccess: (res: any) => {
            setSelectedItemId(null);
            setBackUp(false);
            setDeleteGroup(null);
            notification.success({ message: res?.data?.message });
            const arr = {
              ...items,
              items: items?.items?.filter(
                (i: { id: any }) => i?.id !== deleteGroup?.id
              ),
            };
            dispatch(setConversationUser(null));
            dispatch(setContactUser(null));
            dispatch(clearConversation());
            setFirstLoad(true);
            setItems(arr);
          },
          cbFailure: (e: string) => {
            setBackUp(false);
            setDeleteGroup(null);
            notification.error({ message: e });
          },
        })
      );
    }
  }, [deleteGroup, backUp]);
  const edit = (i: any) => {
    modalEdit.confirm({
      centered: true,
      zIndex: 100,
      title: (
        <Title level={3} className="font-size-24 font-weight-500">
          {" "}
          Scheduled messages
        </Title>
      ),
      icon: <ExclamationCircleOutlined className="text-white" />,
      content: (
        <>
          <ConfirmModalPartial contact={i} />
        </>
      ),

      style: { borderRadius: 10 },
      width: "50rem",
      maskClosable: true,
      okText: "",
    });
  };

  useEffect(() => {
    if (contacts && !scrollFetch) {
      setItems(contacts);
    }
  }, [contacts]);

  useEffect(() => {
    if (
      firstLoad &&
      contacts &&
      contacts?.items?.length > 0 &&
      items?.items?.length > 0
    ) {
      dispatch(
        RequestAppAction.fetchNotes({
          id: items?.items[0]?.id,
          meta: PAGINATION,
        })
      );
      dispatch(setContactUser(items.items[0]));
      dispatch(
        RequestAppAction.getUser({
          id: items?.items[0]?.id,
        })
      );
      setFirstLoad(false);
    }
  }, [items]);

  const onClickUser = (item: { id: string }) => {
    dispatch(
      RequestAppAction.fetchNotes({
        id: item?.id,
      })
    );
    dispatch(setContactUser(item));
    dispatch(
      RequestAppAction.getUser({
        id: item?.id,
      })
    );
  };

  const fetchNewData = () => {
    dispatch(
      RequestAppAction.fetchContacts({
        id: did,
        meta: PAGINATION,
        cbSuccess: () => {
          setFetchingData(false);
          setPagination((pre: any) => ({
            ...pre,
            page: PAGINATION.page + 1,
          }));
        },
        cbFailure: (e: string) => {
          setFetchingData(false);
          notification.error({ message: e });
        },
      })
    );
  };

  useEffect(() => {
    if (did) {
      fetchNewData();
      setFirstLoad(true);
    }
  }, [did]);

  useEffect(() => {
    if (scrollFetch) {
      const handler = setTimeout(() => {
        dispatch(
          RequestAppAction.fetchContacts({
            id: did,
            meta: pagination,
            cbSuccess: (data: any) => {
              setFetchingData(false);
              setItems((pre: any) => ({
                ...pre,
                items: [...items?.items, ...data?.data?.items],
              }));
              setScrollFetch(false);
              setPagination((pre: any) => ({
                ...pre,
                page: pagination.page + 1,
              }));
            },
            cbFailure: (e: string) => {
              setScrollFetch(false);
              setFetchingData(false);
              notification.error({ message: e });
            },
          })
        );
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [scrollFetch]);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 10;

      if (isNearBottom) {
        if (pagination.page <= items?.meta?.totalPages && did) {
          setScrollFetch(true);
        }
      }
    }
  };

  useEffect(() => {
    const listInnerElement: any = scrollRef?.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [items, pagination.page]);

  useEffect(() => {
    return () => {
      setPagination((pre: any) => ({
        ...pre,
        page: PAGINATION.page + 1,
      }));
      if (did) {
        fetchNewData();
      }
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (did) {
        dispatch(
          RequestAppAction.fetchContacts({
            id: did,
            meta: { ...PAGINATION, search: search },
            cbSuccess: () => {
              setFetchingData(false);
              setPagination((pre: any) => ({
                ...pre,
                page: PAGINATION.page + 1,
              }));
            },
            cbFailure: (e: string) => {
              setFetchingData(false);
              notification.error({ message: e });
            },
          })
        );
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (
      selectedContacts.length === items?.items?.length &&
      items?.items?.length > 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedContacts, items?.items]);

  const deleteAll = () => {
    modalDele.confirm({
      centered: true,
      title: t("modal.confirmDeleteAllContacts"),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
            <p>{t("sideBar.sendBackupToEmail")}</p>
            <div>
              <Switch
                onChange={(e) => {
                  setBackUp(e);
                }}
                checkedChildren={t("switch.on")}
                unCheckedChildren={t("switch.off")}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center pb-3"></div>
        </div>
      ),
      okText: t("button.delete"),
      cancelText: t("button.cancel"),
      style: { borderRadius: 10 },
      onOk: () => {
        setBulkDelete(selectedContacts);
      },
    });
  };

  return (
    <div style={{ height: "81vh", position: "relative" }}>
      <Space
        direction="vertical"
        style={{ height: 140, background: "#F7941D" }}
        className="header_title_wrapper"
      >
        <div className="d-flex align-items-center justify-content-between pl-5 pr-5">
          <Text
            style={{ marginBottom: "-0.45rem" }}
            className="header_title font-size-14"
          >
            {t("sideBar.contacts")}
          </Text>
          <Col
            style={{ marginBottom: "-0.6rem" }}
            className="d-flex align-items-center "
          >
            <Tooltip title={t("message.importExportContacts")}>
              <Btn
                size="small"
                onClick={onClickImport}
                icon={<RetweetOutlined />}
                className="d-flex align-items-center justify-content-center"
                style={{
                  marginLeft: "0rem",
                  marginRight: "0.5rem",
                  borderRadius: 50,
                  padding: "0.8rem",
                }}
              />
            </Tooltip>
            <Tooltip title={t("message.addNewContacts")}>
              <Btn
                size="small"
                onClick={openContactModal}
                className="d-flex align-items-center justify-content-center"
                icon={<PlusSquareOutlined />}
                style={{
                  borderRadius: 50,
                  padding: "0.8rem",
                }}
              />
            </Tooltip>
          </Col>
        </div>
        <div className="d-flex align-items-center justify-content-evenly">
          <Text className="text-white font-size-12 font-weight-300 ">
            {contacts?.meta?.totalCount || 0} {t("sideBar.contacts")}
          </Text>
        </div>
        <div className="search-container">
          <Input
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            placeholder={t("placeholder.searchHere")}
            style={{ borderRadius: "8px", background: "light" }}
            suffix={
              search?.length > 0 ? (
                <CloseOutlined
                  onClick={() => {
                    setSearch("");
                  }}
                  style={{ cursor: "pointer", color: "rgba(0,0,0,.45)" }}
                />
              ) : (
                <></>
              )
            }
          />
        </div>
        <div className="d-flex align-items-center justify-content-between pl-4 pr-4">
          <Checkbox
            checked={selectAll}
            onChange={() => {
              if (selectAll) {
                setSelectedContacts([]);
              } else {
                const arr: any = [];

                items?.items?.map((item: any) => {
                  arr.push(item.id);
                });
                setSelectedContacts(arr);
              }
            }}
            className="text-white font-size-12 font-weight-300"
          >
            {t("checkBox.selectAll")}
          </Checkbox>
          {selectedContacts?.length > 0 && (
            <div onClick={deleteAll}>
              <Text className="text-white font-size-12 cursor-pointer">
                <DeleteOutlined style={{ color: "white", padding: "0.5rem" }} />
                {t("common.deleteSelected")}
              </Text>
            </div>
          )}
        </div>
      </Space>
      <div
        ref={scrollRef}
        style={{
          height: "95%",
          overflowY: "auto",
          paddingBottom: "2rem",
          overflowX: "hidden",
        }}
      >
        {items?.items?.length > 0 ? (
          items?.items?.map((i: any, index: number) => (
            <div key={index}>
              <Row
                style={{
                  background:
                    currentUser?.id === i?.id
                      ? Colors.SelectedItemGrey
                      : Colors.white,
                  height: "5.2rem",
                }}
                key={index}
              >
                <Col
                  span={selectedItemId === i.id ? 4 : 5}
                  order={1}
                  className="d-flex align-items-center justify-content-around p-2"
                >
                  {selectedItemId !== i.id && (
                    <div>
                      <Checkbox
                        checked={selectedContacts.includes(i?.id)}
                        onChange={() => {
                          if (selectedContacts.includes(i?.id)) {
                            setSelectedContacts(
                              selectedContacts.filter(
                                (item: any) => item !== i?.id
                              )
                            );
                          } else {
                            setSelectedContacts((pre: any) => [...pre, i?.id]);
                          }
                        }}
                      />
                    </div>
                  )}
                  <div
                    onClick={() => {
                      dispatch(
                        RequestAppAction.fetchNotes({
                          id: i?.id,
                          meta: PAGINATION,
                          cbSuccess: (data: any) => {
                            onPressContact(data?.data);
                          },
                        })
                      );
                      dispatch(setContactUser(i));
                      dispatch(
                        RequestAppAction.getUser({
                          id: i?.id,
                        })
                      );
                    }}
                    className="text-center cursor-pointer"
                  >
                    <div>
                      <Avatar
                        size={40}
                        style={{
                          background: Colors.IconBackground,
                          color: Colors.IconColor,
                        }}
                      >
                        {i?.firstName ? (
                          i?.firstName?.charAt(0).toUpperCase()
                        ) : (
                          <UserOutlined />
                        )}
                      </Avatar>
                    </div>
                  </div>
                </Col>
                <Col
                  onClick={() => {
                    dispatch(
                      RequestAppAction.fetchNotes({
                        id: i?.id,
                        meta: PAGINATION,
                        cbSuccess: (data: any) => {
                          onPressContact(data?.data);
                        },
                      })
                    );
                    dispatch(setContactUser(i));
                    dispatch(
                      RequestAppAction.getUser({
                        id: i?.id,
                      })
                    );
                  }}
                  span={selectedItemId === i.id ? 14 : 18}
                  order={2}
                  className="d-flex align-items-center cursor-pointer  justify-content-between p-2"
                >
                  <Col span={2}></Col>
                  <Col span={20}>
                    <div style={{ overflow: "hidden" }}>
                      <Text
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className="font-size-16"
                      >
                        {i?.firstName || i?.phoneNumber}{" "}
                      </Text>
                      <Text
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontWeight: "600",
                        }}
                        className="font-size-16"
                      >
                        {i?.firstName && i?.lastName}
                        {i?.companyName && (
                          <Text
                            className="font-size-12 pl-3"
                            style={{ fontWeight: "500" }}
                          >
                            {`${i?.companyName}`}
                          </Text>
                        )}
                      </Text>
                    </div>

                    {i?.firstName && (
                      <>
                        <Text className="font-size-11">
                          {i?.firstName ? i?.phoneNumber : ""}
                        </Text>

                        <br />
                      </>
                    )}
                  </Col>
                  <Col
                    span={2}
                    className="d-flex align-items-center justify-content-center"
                  ></Col>
                </Col>
                <Col
                  span={selectedItemId === i.id ? 1 : 1}
                  order={3}
                  className="d-flex align-items-center justify-content-center p-2 box-shadow-right"
                >
                  <Space>
                    <EllipsisOutlined
                      onClick={() => toggleFourthColumn(i.id)}
                      style={{
                        fontSize: "24px",
                        cursor: "pointer",
                        transform: "rotate(90deg)",
                        color: Colors.TitleGray,
                      }}
                    />
                  </Space>
                </Col>
                {selectedItemId === i.id && (
                  <Col
                    span={selectedItemId === i.id ? 5 : 0}
                    order={4}
                    style={{
                      opacity: selectedItemId === i.id ? 1 : 0,
                      transition: "opacity 3.41s ease",
                      background: "#f4f6fa",
                    }}
                    className="d-flex align-items-center justify-content-around"
                  >
                    <Space direction="horizontal">
                      <Tooltip
                        title={
                          i?.scheduledMessageCount > 0
                            ? `${i?.scheduledMessageCount < 10 ? 0 : ""}${
                                i?.scheduledMessageCount
                              }`
                            : ""
                        }
                      >
                        <Btn
                          style={{
                            background:
                              i?.scheduledMessageCount > 0
                                ? Colors.Secondary
                                : Colors.white,
                          }}
                          shape="circle"
                          onClick={() => edit(i)}
                          icon={
                            <ClockCircleOutlined
                              style={{
                                color:
                                  i?.scheduledMessageCount > 0
                                    ? Colors.white
                                    : Colors.DarkCharcoal,
                              }}
                            />
                          }
                        />
                      </Tooltip>
                      <Btn
                        shape="circle"
                        onClick={() => dele(i)}
                        icon={<DeleteOutlined />}
                      />
                    </Space>
                  </Col>
                )}
              </Row>
              <Divider orientation="left" style={{ margin: "0px" }} />
            </div>
          ))
        ) : fetchingData ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              color: "#ccc",
              fontSize: "1rem",
              fontWeight: 300,
              marginTop: "2rem",
            }}
          >
            {t("common.fetchingData")}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              color: "#ccc",
              fontSize: "1rem",
              fontWeight: 300,
              marginTop: "2rem",
            }}
          >
            {t("common.noRecordFound")}
          </div>
        )}
        {scrollFetch && (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Spin></Spin>
          </div>
        )}
      </div>
      <div>{contextHolderEdit}</div>
      <div>{contextHolderDel}</div>
      <div>{contextHolderAdd}</div>
    </div>
  );
};

export default ListingSidebarContacts;
