import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { fetchPlansFailure, fetchPlansSuccess } from "../slices/features/plan";
import { RequestTypes } from "../types";

const appService = new AppService();

function* plansSaga(action: any) {
  const { payload } = action;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.fetchPlan,
      baseUrl
    );
    if (response) {
      yield put(fetchPlansSuccess({ ...response }));
      payload?.cbSuccess && payload?.cbSuccess();
    }
  } catch (error: any) {
    const { statusCode, statusText } = error;
    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);
    yield put(fetchPlansFailure({ statusCode, statusText }));
  }
}

export function* watchfetchPlans() {
  yield takeLatest(RequestTypes.PLANS_REQUEST, plansSaga);
}
