import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  deleteState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const deleteSlice = createSlice({
  name: "delete",
  initialState: INITIAL_STATE,
  reducers: {
    fetchDeleteContactData: (state) => {
      state.deleteState.isLoading = true;
    },
    fetchDeleteContactSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchDeleteContactFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchDeleteConversationData: (state) => {
      state.deleteState.isLoading = true;
    },
    fetchDeleteConversationSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchDeleteConversationFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchDeleteGroupData: (state) => {
      state.deleteState.isLoading = true;
    },
    fetchDeleteGroupSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchDeleteGroupFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchDeleteBulkContactData: (state) => {
      state.deleteState.isLoading = true;
    },
    fetchDeleteBulkContactSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchDeleteBulkContactFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchDeleteBulkConversationData: (state) => {
      state.deleteState.isLoading = true;
    },
    fetchDeleteBulkConversationSuccess: (
      state,
      action: PayloadAction<any>
    ) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchDeleteBulkConversationFailure: (
      state,
      action: PayloadAction<any>
    ) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchDeleteBulkGroup: (state) => {
      state.deleteState.isLoading = true;
    },
    fetchDeleteBulkGroupSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchDeleteBulkGroupFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      deleteState: {
        ...state.deleteState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  fetchDeleteContactData,
  fetchDeleteContactFailure,
  fetchDeleteContactSuccess,
  fetchDeleteConversationData,
  fetchDeleteConversationFailure,
  fetchDeleteConversationSuccess,
  fetchDeleteGroupData,
  fetchDeleteGroupFailure,
  fetchDeleteGroupSuccess,
  fetchDeleteBulkContactData,
  fetchDeleteBulkContactFailure,
  fetchDeleteBulkContactSuccess,
  fetchDeleteBulkConversationFailure,
  fetchDeleteBulkConversationData,
  fetchDeleteBulkConversationSuccess,
  fetchDeleteBulkGroup,
  fetchDeleteBulkGroupFailure,
  fetchDeleteBulkGroupSuccess,
} = deleteSlice.actions;
export const deleteReducer = deleteSlice.reducer;
