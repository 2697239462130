import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  workingDayState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  postApiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const workingDaysSlice = createSlice({
  name: "workingDays",
  initialState: INITIAL_STATE,
  reducers: {
    fetchWorkingDaysData: (state) => {
      state.workingDayState.isLoading = true;
    },
    fetchWorkingDaysSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      workingDayState: {
        ...state.workingDayState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchWorkingDaysFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      workingDayState: {
        ...state.workingDayState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    postWorkingDaysData: (state) => {
      state.workingDayState.isLoading = true;
    },
    postWorkingDaysSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      workingDayState: {
        ...state.workingDayState,
        error: null,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    postWorkingDaysFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      workingDayState: {
        ...state.workingDayState,
        error: action.payload?.message,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    putWorkingDaysData: (state) => {
      state.workingDayState.isLoading = true;
    },
    putWorkingDaysSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      workingDayState: {
        ...state.workingDayState,
        error: null,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    putWorkingDaysFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      workingDayState: {
        ...state.workingDayState,
        error: action.payload?.message,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    deleteWorkingDaysData: (state) => {
      state.workingDayState.isLoading = true;
    },
    deleteWorkingDaysSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      workingDayState: {
        ...state.workingDayState,
        error: null,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    deleteWorkingDaysFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      workingDayState: {
        ...state.workingDayState,
        error: action.payload?.message,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearWorkingDays: (state) => ({
      ...state,
      workingDayState: {
        ...state.workingDayState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: null,
      },
    }),
  },
});

export const {
  fetchWorkingDaysData,
  fetchWorkingDaysFailure,
  fetchWorkingDaysSuccess,
  postWorkingDaysData,
  postWorkingDaysFailure,
  postWorkingDaysSuccess,
  putWorkingDaysData,
  putWorkingDaysFailure,
  putWorkingDaysSuccess,
  deleteWorkingDaysData,
  deleteWorkingDaysFailure,
  deleteWorkingDaysSuccess,
  clearWorkingDays,
} = workingDaysSlice.actions;
export const workingDaysReducer = workingDaysSlice.reducer;
