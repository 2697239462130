import { createSelector } from "reselect";

const plansSelector = (state: TReduxState) => state.features.plans;

export const getBaseUrl = createSelector(plansSelector, (app) => app.baseUrl);

export const getPlanData = createSelector(
  plansSelector,
  (app) => app.apiStatus.data
);

export const getPlanState = createSelector(plansSelector, (app) => app.plan);

export const getPlanLoading = createSelector(
  getPlanState,
  (states) => states?.isLoading
);
