import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchBulkContactsFailure,
  fetchBulkContactsSuccess,
} from "../slices/features/bulkMessageContacts";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchBulkContactSaga(action: any) {
  const { payload } = action;
  const { id, meta } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchContacts,
      baseUrl,
      id,
      meta
    );

    yield put(fetchBulkContactsSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;
    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchBulkContactsFailure({ statusCode, statusText }));
  }
}

export function* watchfetchBulkContacts() {
  yield takeLatest(RequestTypes.BULK_MESSAGE_CONTACTS, fetchBulkContactSaga);
}
