export const replaceContentVariables = (content: string, variables: []) => {
  let replacedContent = content;
  if (variables && variables.length > 0) {
    variables.forEach((variable: any) => {
      if (variable.value) {
        const regex = new RegExp(variable.placeholder, "g");
        replacedContent = replacedContent.replace(regex, variable.value);
      }
    });
  }
  return replacedContent;
};
