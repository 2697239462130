import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { RequestTypes } from "../types";
import { deleteTemplateFailure, deleteTemplateSuccess } from "../slices/features/postTemplate";

const appservice = new AppService();

function* deleteTemplateSaga(action: any) {
  const { payload } = action;
  const { id } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.deleteTemplate,
      baseUrl,
      id
    );

    yield put(deleteTemplateSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(deleteTemplateFailure({ statusCode, statusText }));
  }
}

export function* watchDeleteTemplate() {
  yield takeLatest(RequestTypes.DELETE_TEMPLATE_REQUEST, deleteTemplateSaga);
}
