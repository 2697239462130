import { memo } from "react";
import { useSelector } from "react-redux";
import { getLoginData } from "src/store/selectors/features/auth";
import PublicRoutes from "./public-routes";

const Auth = () => {
  const loggedIn = useSelector(getLoginData);

  return <PublicRoutes loggedIn={loggedIn} />;
};

export default memo(Auth);
