import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchDownloadGroupMessagesFailure,
  fetchDownloadGroupMessagesSuccess,
} from "../slices/features/groups";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* downloadGroupsSaga(action: any) {
  const { payload } = action;
  const { did, groupId } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.downloadGroupMessages,
      baseUrl,
      did,
      groupId
    );

    yield put(fetchDownloadGroupMessagesSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchDownloadGroupMessagesFailure({ statusCode, statusText }));
  }
}

export function* watchDownlaodGroup() {
  yield takeLatest(RequestTypes.DOWNLOAD_GROUP_MESSAGES, downloadGroupsSaga);
}
