import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { fetchEventsFailure, fetchEventsSuccess } from "../slices/features/fetchEvents";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchEventsSaga(action: any) {
  const { payload } = action;
  const { id, meta } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchEvents,
      baseUrl,
      id,
      meta
    );

    yield put(fetchEventsSuccess({ ...response, items: [] }));

    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchEventsFailure({ statusCode, statusText }));
  }
}

export function* watchfetchEvents() {
  yield takeLatest(RequestTypes.GET_EVENT_REQUEST, fetchEventsSaga);
}
