import { createSelector } from "reselect";

const contactSelector = (state: TReduxState) => state.features.contact;

export const getBaseUrl = createSelector(contactSelector, (app) => app.baseUrl);

export const getContactData = createSelector(
  contactSelector,
  (app) => app.apiStatus.data
);

export const getContactState = createSelector(
  contactSelector,
  (app) => app.contacts
);

export const getContactLoading = createSelector(
  getContactState,
  (states) => states?.isLoading
);
