import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  login: {
    isLoading: false,
    error: null,
  },
  forgetPassword: {
    data: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  apiStatusForgetPassword: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  userData: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState: INITIAL_STATE,
  reducers: {
    fetchLoginData: (state) => {
      state.login.isLoading = true;
    },
    fetchLoginDataSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      login: {
        ...state.login,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      userData: action.payload.data,
    }),
    fetchLoginDataFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      login: {
        ...state.login,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    logoutUser: (state) => {
      state.userData = null;
    },
    postForgetPassword: (state) => {
      state.login.isLoading = true;
    },
    postForgetPasswordSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      apiStatusForgetPassword: {
        ...state.apiStatusForgetPassword,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    postForgetPasswordFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      login: {
        ...state.login,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatusForgetPassword: {
        ...state.apiStatusForgetPassword,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: null,
      },
    }),
  },
});

export const {
  fetchLoginData,
  fetchLoginDataSuccess,
  fetchLoginDataFailure,
  logoutUser,
  postForgetPassword,
  postForgetPasswordFailure,
  postForgetPasswordSuccess,
} = loginSlice.actions;
export const LoginReducer = loginSlice.reducer;
