import {
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  PlusSquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button as Btn,
  Checkbox,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import { PAGINATION } from "src/constants/common";
import { formattedTime } from "src/constants/functions";
import { LocalStorageService } from "src/services/local-storage";
import { getConversationUser } from "src/store/selectors/features/conversationUser";
import { getConversationsData } from "src/store/selectors/features/conversations";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import { getBaseUrl } from "src/store/selectors/features/preference";
import RequestAppAction from "src/store/slices/appActions";
import { updateConversationsData } from "src/store/slices/features/conversations";
import { clearConversationById } from "src/store/slices/features/conversationsById";
import { setConversationUser } from "src/store/slices/features/currentUserConversation";
import Colors from "src/themes/colors";
import { AddScheduleMessageModal } from "./addScheduleMessageModal";
import "./index.scss";
import useWindowDimensions from "src/hooks/useWindowDimensions";

interface modalProps {
  openModal: (text: string) => void;
  closeModal: () => void;
}

const localStorageService = new LocalStorageService();

interface props {
  openModalFav: () => void;
  templateModalRef?: any;
  socketUpdateList: (res: any) => void;
  getUserFun: (res: any) => void;
}

let socketDid: any;

function GetRowOrCol ({
  width,
  span,
  order,
  classname,
  onclick = () => {},
  children,
}: {
  width: number;
  span: number;
  order: number;
  classname: string;
  onclick?: () => void;
  children: any;
}) {
  if (width && width <= 768) {
    return (
      <Row onClick={onclick} className={classname}>
        {children}
      </Row>
    );
  }
  return (
    <Col span={span} order={order} onClick={onclick} className={classname}>
      {children}
    </Col>
  );
};

const ListingSidebar = ({
  openModalFav,
  templateModalRef,
  socketUpdateList,
  getUserFun,
}: props) => {
  const [form] = useForm();
  const { width } = useWindowDimensions();
  const { Text } = Typography;

  const [modalDele, contextHolderDel] = Modal.useModal();
  const modalRef = useRef<modalProps>();
  const scrollRef: any = useRef(null);
  const conversations: any = useSelector(getConversationsData);
  const [pagination, setPagination] = useState(PAGINATION);
  const did = useSelector(getCurrentDidId);
  const currentUser: any = useSelector(getConversationUser);
  const [scrollFetch, setScrollFetch] = useState(false);
  const [allConversations, setAllConversations] = useState<any>(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const [showUnreadMessages, setShowUnreadMessages] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [token, setToken] = useState<string | null>(null);
  const [deleteGroup, setDeleteGroup] = useState<{
    contacts: { id: string };
  } | null>(null);
  const [deleteAllCon, setDeleteAllCon] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const baseUrl = useSelector(getBaseUrl);
  const [selectedConversations, setSelectedConversations] = useState<any>([]);
  const [backUp, setBackUp] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  const [isSelecting, setIsSelecting] = useState(false);

  const toggleFourthColumn = (itemId: number) => {
    setSelectedItemId((prevId) => (prevId === itemId ? null : itemId));
  };

  const dele = (data: any) => {
    modalDele.confirm({
      centered: true,
      title: t("modal.confirmDelete"),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
            <p>{t("sideBar.sendBackupToEmail")}</p>
            <div>
              <Switch
                onChange={(e) => {
                  setBackUp(e);
                }}
                checkedChildren={t("switch.on")}
                unCheckedChildren={t("switch.off")}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center pb-3"></div>
        </div>
      ),
      okText: t("button.delete"),
      cancelText: t("button.cancel"),
      style: { borderRadius: 10 },
      onOk: () => {
        setDeleteGroup(data);
      },
      width: "500px",
    });
  };

  useEffect(() => {
    if (deleteGroup) {
      dispatch(
        RequestAppAction.deleteConversation({
          id: deleteGroup?.contacts?.id,
          meta: backUp ? { backup: backUp } : "",
          cbSuccess: (res: any) => {
            setSelectedItemId(null);
            setBackUp(false);
            setDeleteGroup(null);
            notification.success({ message: res?.data?.message });
            const arr = {
              ...allConversations,
              items: allConversations?.items?.filter(
                (i: { contacts: any }) =>
                  i?.contacts?.id !== deleteGroup?.contacts?.id
              ),
            };

            if (arr?.items?.length > 0) {
              getUserFun(arr?.items[0]?.contacts);
              dispatch(setConversationUser(arr?.items[0]?.contacts));
              dispatch(
                RequestAppAction.fetchConversationsById({
                  id: arr?.items[0]?.contactId,
                  meta: PAGINATION,
                  cbSuccess: () => {},
                  cbFailure: (e: string) => {
                    notification.error({ message: e });
                  },
                })
              );
            } else {
              dispatch(setConversationUser(null));
              getUserFun(null);
              dispatch(clearConversationById());
            }

            setAllConversations(arr);
          },
          cbFailure: (e: string) => {
            setBackUp(false);
            setDeleteGroup(null);
            notification.error({ message: e });
          },
        })
      );
    }
  }, [deleteGroup, backUp]);

  useEffect(() => {
    if (conversations && !scrollFetch && !isSelecting) {
      const arr = {
        ...conversations,
        items: conversations?.items?.filter(
          (i: { contacts: any }) => i?.contacts != null
        ),
      };
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
      setAllConversations(arr);
    }
  }, [conversations]);

  useEffect(() => {
    if (!location.state && firstLoad && allConversations?.items?.length > 0) {
      dispatch(
        setConversationUser(
          conversations?.items[0]?.contacts ??
            allConversations?.items[0]?.contacts
        )
      );
      getUserFun(allConversations?.items[0]?.contacts);
      setFirstLoad(false);
    }
    if (location.state && firstLoad) {
      getUserFun(location.state);
      dispatch(setConversationUser(location.state));
      setFirstLoad(false);
    }
  }, [allConversations]);

  useEffect(() => {
    getUserFun(null);
  }, [did]);

  const add = (i: { contacts: { firstName: any; phoneNumber: any } }) => {
    form.resetFields();
    setTimeout(() => {
      modalRef.current?.openModal(
        t("common.newScheduledMessagefor", {
          name: i?.contacts?.firstName || i?.contacts?.phoneNumber || "",
        })
      );
    }, 100);
  };

  const fetchNewData = () => {
    if (did) {
      const meta: any = { ...PAGINATION };
      if (showUnreadMessages) {
        meta["unread"] = true;
      }

      dispatch(
        RequestAppAction.fetchConversations({
          id: did,
          meta: meta,
          cbSuccess: () => {
            setPagination((pre: any) => ({
              ...pre,
              page: PAGINATION.page + 1,
            }));
            setFetchingData(false);
          },
          cbFailure: (e: string) => {
            notification.error({ message: e });
            setFetchingData(false);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (did) {
      fetchNewData();
      setFirstLoad(true);
    }
  }, [did]);

  useEffect(() => {
    if (scrollFetch) {
      const handler = setTimeout(() => {
        const meta: any = { ...pagination, search: search };
        if (showUnreadMessages) {
          meta["unread"] = true;
        }
        dispatch(
          RequestAppAction.fetchConversations({
            id: did,
            meta: meta,
            cbSuccess: (data: any) => {
              setAllConversations((pre: any) => ({
                ...pre,
                items: [...allConversations?.items, ...data?.data?.items],
              }));
              setScrollFetch(false);
              setPagination((pre: any) => ({
                ...pre,
                page: pagination.page + 1,
              }));
              setFetchingData(false);
            },
            cbFailure: (e: string) => {
              setScrollFetch(false);

              notification.error({ message: e });
            },
          })
        );
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [scrollFetch]);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 10;

      if (isNearBottom) {
        if (pagination.page <= allConversations.meta?.totalPages) {
          setScrollFetch(true);
        }
      }
    }
  };

  useEffect(() => {
    const listInnerElement: any = scrollRef?.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [allConversations, pagination.page]);

  useEffect(() => {
    return () => {
      setPagination((pre: any) => ({
        ...pre,
        page: PAGINATION.page + 1,
      }));
      dispatch(setConversationUser(null));
      dispatch(clearConversationById());
    };
  }, []);

  const getToken = async () => {
    const token = await localStorageService.fetch("authToken");

    if (token) {
      setToken(token);
    }
  };

  useEffect(() => {
    getToken();

    return () => {
      setToken("");
      getUserFun(null);
      dispatch(setConversationUser(null));
    };
  }, []);

  socketDid = did;

  useEffect(() => {
    if (token) {
      const socket = io(`${baseUrl}events?Authorization=Bearer ${token}`, {
        transports: ["websocket"],
      });

      socket.on("newMessage", (res) => {
        if (res) {
          socketUpdateList(res);

          if (res?.didId === socketDid || res?.senderDid === socketDid) {
            const meta: any = { ...PAGINATION };
            if (showUnreadMessages) {
              meta["unread"] = true;
            }
            dispatch(
              RequestAppAction.fetchConversations({
                id: socketDid,
                meta: meta,
                cbSuccess: () => {
                  setPagination((pre: any) => ({
                    ...pre,
                    page: PAGINATION.page + 1,
                  }));
                },
                cbFailure: (e: string) => {
                  notification.error({ message: e });
                },
              })
            );
          }
        }
      });

      return () => {
        socket.off();
      };
    }
  }, [token]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (did) {
        const meta: any = { ...PAGINATION, search: search };
        if (showUnreadMessages) {
          meta["unread"] = true;
        }
        dispatch(
          RequestAppAction.fetchConversations({
            id: did,
            meta: meta,
            cbSuccess: () => {
              setFetchingData(false);
              setPagination((pre: any) => ({
                ...pre,
                page: PAGINATION.page + 1,
              }));
            },
            cbFailure: (e: string) => {
              setFetchingData(false);
              notification.error({ message: e });
            },
          })
        );
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (
      selectedConversations.length === allConversations?.items?.length &&
      allConversations?.items?.length > 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedConversations, allConversations?.items]);

  const deleteAll = () => {
    modalDele.confirm({
      centered: true,
      title: t("modal.confirmDeleteAllContacts"),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
            <p>{t("sideBar.sendBackupToEmail")}</p>
            <div>
              <Switch
                onChange={(e) => {
                  setBackUp(e);
                }}
                checkedChildren={t("switch.on")}
                unCheckedChildren={t("switch.off")}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center pb-3"></div>
        </div>
      ),
      okText: t("button.delete"),
      cancelText: t("button.cancel"),
      style: { borderRadius: 10 },
      onOk: () => {
        setDeleteAllCon(true);
      },
    });
  };

  useEffect(() => {
    if (deleteAllCon) {
      dispatch(
        RequestAppAction.deleteBulkConversations({
          data: { contactId: selectedConversations },
          meta: backUp ? { backup: backUp } : "",
          cbSuccess: (res: any) => {
            setDeleteAllCon(false);
            setSelectedConversations([]);
            setFirstLoad(true);
            dispatch(setConversationUser(null));
            dispatch(clearConversationById());
            notification.success({ message: res?.data?.message });
            fetchNewData();
          },
          cbFailure: (e: string) => {
            notification.error({ message: e });
          },
        })
      );
    }
  }, [deleteAllCon]);

  const updateCount = (i: { contactId: string }) => {
    const itemConversation = { ...allConversations };

    let updatedArr = itemConversation?.items?.map(
      (item: { contacts: { id: string }; unreadCount: number }) => {
        if (item?.contacts?.id === i?.contactId) {
          return { ...item, unreadCount: 0 };
        } else {
          return { ...item };
        }
      }
    );

    const totalCount = updatedArr.reduce(
      (
        accumulator: number,
        currentValue: {
          unreadCount: number;
        }
      ) => {
        return accumulator + currentValue.unreadCount;
      },
      0
    );

    const newObj = {
      items: updatedArr,
      meta: { ...itemConversation?.meta, totalUnread: totalCount },
    };

    dispatch(updateConversationsData({ data: newObj }));
    setAllConversations(newObj);
    setTimeout(() => {
      setIsSelecting(false);
    }, 100);
  };

  const onSelectUser = (i: {
    contactId: string;
    contacts: any;
    unreadCount: number;
  }) => {
    getUserFun(i);
    setIsSelecting(true);
    dispatch(
      RequestAppAction.fetchConversationsById({
        id: i?.contactId,
        meta: PAGINATION,
        cbSuccess: () => {
          getUserFun(i?.contacts);
          dispatch(setConversationUser(i?.contacts));
          updateCount(i);
        },
      })
    );
  };

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Space
        direction="vertical"
        style={{ height: 140, background: "#F7941D" }}
        className="header_title_wrapper"
      >
        <div className="d-flex align-items-center justify-content-between pl-5 pr-5">
          <Text
            style={{ marginBottom: "-0.45rem" }}
            className="header_title font-size-14"
          >
            {t("sideBar.conversations")}
          </Text>
          <Tooltip title={t("message.sendNewMessage")}>
            <Col style={{ marginBottom: "-0.6rem" }}>
              <Btn
                size="small"
                onClick={openModalFav}
                className="d-flex align-items-center justify-content-center"
                icon={<PlusSquareOutlined />}
                style={{
                  borderRadius: 50,
                  padding: "0.8rem",
                }}
              />
            </Col>
          </Tooltip>
        </div>
        <div className="d-flex align-items-center justify-content-evenly">
          <Text className="text-white font-size-12 font-weight-300 ">
            {conversations?.meta?.totalCount || 0} {t("sideBar.conversations")}
          </Text>
          <Text className="text-white font-size-12 font-weight-300">
            {allConversations?.meta?.totalUnread ?? 0}{" "}
            {t("sideBar.newMessages")}
          </Text>
        </div>
        <div className="search-container">
          <Input
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            placeholder={t("placeholder.searchHere")}
            style={{ borderRadius: "8px", background: "light" }}
            suffix={
              search?.length > 0 ? (
                <CloseOutlined
                  onClick={() => {
                    setSearch("");
                  }}
                  style={{ cursor: "pointer", color: "rgba(0,0,0,.45)" }}
                />
              ) : (
                <></>
              )
            }
          />
        </div>
        <div className="d-flex align-items-center justify-content-between pl-4 pr-4">
          <Checkbox
            checked={selectAll}
            onChange={() => {
              if (selectAll) {
                setSelectedConversations([]);
              } else {
                const arr: any = [];

                allConversations?.items?.map((item: any) => {
                  arr.push(item?.contacts?.id);
                });
                setSelectedConversations(arr);
              }
            }}
            className="text-white font-size-12 font-weight-300"
          >
            {t("checkBox.selectAll")}
          </Checkbox>
          {selectedConversations?.length > 0 && (
            <div onClick={deleteAll}>
              <Text className="text-white font-size-12 cursor-pointer">
                <DeleteOutlined style={{ color: "white", padding: "0.5rem" }} />
                {t("common.deleteSelected")}
              </Text>
            </div>
          )}
          <Checkbox
            onChange={(e) => {
              const meta: any = { ...PAGINATION, search: search };
              if (e.target.checked) {
                meta["unread"] = true;
              }

              dispatch(
                RequestAppAction.fetchConversations({
                  id: did,
                  meta: meta,
                  cbSuccess: () => {
                    setPagination((pre: any) => ({
                      ...pre,
                      page: PAGINATION.page + 1,
                    }));
                  },
                  cbFailure: (e: string) => {
                    notification.error({ message: e });
                  },
                })
              );

              setShowUnreadMessages(e.target.checked);
            }}
            className="text-white font-size-12 font-weight-300"
          >
            {t("checkBox.showUnreadMessages")}
          </Checkbox>
        </div>
      </Space>
      <div
        ref={scrollRef}
        style={{
          height: "calc(100% - 140px)",
          overflowY: "scroll",
          // paddingBottom: "2rem",
          overflowX: "hidden",
        }}
      >
        {allConversations && allConversations?.items?.length > 0 ? (
          allConversations?.items?.map(
            (i: any, index: number) =>
              i?.contacts && (
                <div key={index}>
                  <Row
                    style={{
                      cursor: "pointer",
                      background:
                        currentUser?.id === i?.contactId
                          ? Colors.SelectedItemGrey
                          : Colors.white,
                    }}
                    key={index}
                  >
                    {selectedItemId !== i?.id && (
                      <Col
                        span={1}
                        order={1}
                        className="d-flex align-items-center justify-content-around pl-2"
                      >
                        <Checkbox
                          checked={selectedConversations.includes(
                            i?.contacts?.id
                          )}
                          onChange={() => {
                            if (
                              selectedConversations.includes(i?.contacts?.id)
                            ) {
                              setSelectedConversations(
                                selectedConversations.filter(
                                  (item: any) => item !== i?.contacts?.id
                                )
                              );
                            } else {
                              setSelectedConversations((pre: any) => [
                                ...pre,
                                i?.contacts?.id,
                              ]);
                            }
                          }}
                        />
                      </Col>
                    )}

                    <Col
                      span={selectedItemId === i?.id ? 19 : 21}
                      order={2}
                      className="d-flex justify-content-around align-items-center p-2"
                    >
                      {/* Contact Profile */}
                      <Col
                        span={
                          width > 898
                            ? (selectedItemId === i?.id ? 6 : 6)
                            : width > 768
                              ? (selectedItemId === i?.id ? 7 : 6)
                              : (selectedItemId === i?.id ? 7 : 7)
                        }
                        order={1}
                        className="d-flex align-items-center justify-content-around pr-2"
                      >
                        <div
                          onClick={() => onSelectUser(i)}
                          className="text-center"
                        >
                          <div>
                            <Avatar
                              size={40}
                              style={{
                                background: Colors.IconBackground,
                                color: Colors.IconColor,
                              }}
                            >
                              {i?.contacts?.firstName ? (
                                i?.contacts.firstName?.charAt(0).toUpperCase()
                              ) : (
                                <UserOutlined />
                              )}
                            </Avatar>
                          </div>
                          <div>
                            <Text className="font-size-10">
                              {i?.updatedAt?.split("T")[0]}
                            </Text>
                          </div>
                          <div>
                            <Text className="font-size-10">
                              {formattedTime(i?.updatedAt)}
                            </Text>
                          </div>
                        </div>
                      </Col>

                      {/* Contact Number & Conversation */}
                      <Col
                        span={
                          width > 898
                            ? (selectedItemId === i?.id ? 16 : 18)
                          : width > 768
                            ? (selectedItemId === i?.id ? 16 : 16)
                            : (selectedItemId === i?.id ? 16 : 18)
                        }
                        order={2}
                        onClick={() => onSelectUser(i)}
                        className="d-flex align-items-center justify-content-between pl-2"
                      >
                        <Col span={width > 898 ? 18 : width > 768 ? 22 : 20}>
                          <Text className="font-size-16">
                            {i?.contacts?.firstName || i?.contacts?.phoneNumber}{" "}
                            <Text
                              className="font-size-16"
                              style={{ fontWeight: "600" }}
                            >
                              {i?.contacts?.firstName && i?.contacts?.lastName}{" "}
                            </Text>
                          </Text>
                          {i?.contacts?.companyName && (
                            <Text className="font-size-12">
                              {`${i?.contacts?.companyName}`}
                            </Text>
                          )}
                          <br />
                          <div style={{ overflow: "hidden" }}>
                            <Text
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              className="font-size-12"
                            >
                              {i?.username ? i?.username + " : " : ""}
                              {i?.content}
                            </Text>
                          </div>
                        </Col>

                        <Col
                          span={3}
                          className="d-flex align-items-center justify-content-center"
                        >
                          {i?.unreadCount && i?.unreadCount > 0 ? (
                            <Tag
                              style={{
                                borderRadius: 50,
                                background: Colors.Secondary,
                                color: Colors.white,
                              }}
                            >
                              {i?.unreadCount}
                            </Tag>
                          ) : null}
                        </Col>
                      </Col>
                    </Col>

                    <Col
                      span={selectedItemId === i?.id ? 1 : 1}
                      order={3}
                      className="d-flex align-items-center justify-content-center p-2 box-shadow-right"
                    >
                      <Space>
                        <EllipsisOutlined
                          onClick={() => toggleFourthColumn(i?.id)}
                          style={{
                            fontSize: "24px",
                            cursor: "pointer",
                            color: Colors.TitleGray,
                            transform: "rotate(90deg)",
                          }}
                        />
                      </Space>
                    </Col>

                    {selectedItemId === i?.id && (
                      <Col
                        span={selectedItemId === i?.id ? 4 : 0}
                        order={4}
                        style={{
                          opacity: selectedItemId === i?.id ? 1 : 0,
                          transition: "opacity 3.41s ease",
                          background: "#f4f6fa",
                        }}
                        className={
                          `${width > 992 ? "d-flex justify-content-around" : "d-flex-column justify-content-center"} align-items-center`
                        }
                      >
                        <Tooltip
                          title={
                            i?.scheduledMessageCount > 0
                              ? `${i?.scheduledMessageCount < 10 ? 0 : ""}${
                                  i?.scheduledMessageCount
                                }`
                              : ""
                          }
                        >
                          <Btn
                            style={{
                              background:
                                i?.scheduledMessageCount > 0
                                  ? Colors.Secondary
                                  : Colors.white,
                            }}
                            shape="circle"
                            onClick={() => add(i)}
                            icon={
                              <ClockCircleOutlined
                                style={{
                                  color:
                                    i?.scheduledMessageCount > 0
                                      ? Colors.white
                                      : Colors.DarkCharcoal,
                                }}
                              />
                            }
                          />
                        </Tooltip>
                        <Btn
                          shape="circle"
                          title=""
                          onClick={() => dele(i)}
                          icon={<DeleteOutlined />}
                        />
                        <AddScheduleMessageModal
                          isEdit={false}
                          modalRef={modalRef}
                          templateModalRef={templateModalRef}
                          contactId={i.contactId}
                          form={form}
                          variableData={{
                            firstName: i.contacts?.firstName,
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                  <Divider orientation="left" style={{ margin: "0px" }} />
                </div>
              )
          )
        ) : fetchingData ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              color: "#ccc",
              fontSize: "1rem",
              fontWeight: 300,
              marginTop: "2rem",
            }}
          >
            {t("common.fetchingData")}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              color: "#ccc",
              fontSize: "1rem",
              fontWeight: 300,
              marginTop: "2rem",
            }}
          >
            {t("common.noRecordFound")}
          </div>
        )}
        {scrollFetch && (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Spin></Spin>
          </div>
        )}
      </div>
      <div>{contextHolderDel}</div>
    </div>
  );
};

export default ListingSidebar;
