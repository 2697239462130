/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Modal,
  Popconfirm,
  Space,
  Spin,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PAGINATION } from "src/constants/common";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import {
  getDeleteTemplateLoading,
  getPostTemplateLoading,
  getPutTemplateLoading,
} from "src/store/selectors/features/postTemplate";
import {
  getTemplateData,
  getTemplateLoading,
} from "src/store/selectors/features/template";
import RequestAppAction from "src/store/slices/appActions";
import { AddTemplateModal } from "./addTemplateModal";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import SettingsLayout from "src/layouts/settings";
import "../setting.scss";

interface modalProps {
  openModal: (text: string) => void;
  closeModal: () => void;
}

const Templates: React.FC = () => {
  const { width } = useWindowDimensions();
  const currentDidId = useSelector(getCurrentDidId);
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const [scrollFetch, setScrollFetch] = useState(false);
  const [_, contextHolderDel] = Modal.useModal();
  const isLoading = useSelector(getPutTemplateLoading);
  const isFetching = useSelector(getTemplateLoading);
  const isDeleteing = useSelector(getDeleteTemplateLoading);
  const isPosting = useSelector(getPostTemplateLoading);
  const templates: any = useSelector(getTemplateData);
  const [pagination, setPagination] = useState(PAGINATION);
  const [items, setItems] = useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = useForm();
  const modalRef = useRef<modalProps>();
  const { t } = useTranslation();

  useEffect(() => {
    if (templates && !scrollFetch) {
      const temp = templates;
      setItems(temp);
    }
  }, [templates]);

  const fetchNewData = () => {
    dispatch(
      RequestAppAction.fetchTemplate({
        id: currentDidId,
        meta: PAGINATION,
        cbSuccess: () => {
          setPagination((pre) => ({
            ...pre,
            page: PAGINATION.page + 1,
          }));
        },
        cbFailure: (e: string) => {
          notification.error({ message: e });
        },
      })
    );
  };

  useEffect(() => {
    if (currentDidId) {
      fetchNewData();
    }
  }, [currentDidId]);

  const add = () => {
    form.resetFields();
    setIsEdit(false);
    setTimeout(() => {
      modalRef.current?.openModal(t("modalHeading.createNewTemplate"));
    }, 100);
  };

  const edit = (i: any) => {
    const charactersLength = i.content.length;
    setIsEdit(true);
    form.setFieldsValue({
      content: i.content,
      id: i.id,
      title: i.title,
      length: charactersLength,
    });
    setTimeout(() => {
      modalRef.current?.openModal(`Edit Template`);
    }, 100);
  };

  const dele = (i: any) => {
    const id = i.id;

    dispatch(
      RequestAppAction.deleteTemplate({
        id: id,
        cbSuccess: () => {
          if (items?.items?.length <= 10) {
            fetchNewData();
          } else {
            setItems((pre: any) => ({
              ...pre,
              items: items?.items?.filter(
                (item: { id: string | number }) => item.id !== id
              ),
            }));
          }
          notification.warning({
            message: t("notification.deleteTemplateSuccess"),
          });
        },
        cbFailure: (e: String) => {
          notification.error({ message: e });
        },
      })
    );
  };

  const addFromResponse = (res: any) => {
    if (items?.items?.length <= 10) {
      fetchNewData();
    } else {
      setItems((pre: any) => ({
        ...pre,
        items: [res?.data, ...pre.items],
      }));
    }
  };

  const cloneTemplate = (template: any) => {
    dispatch(
      RequestAppAction.postTemplate({
        data: {
          content: template.content,
          title: template.title,
        },
        id: currentDidId,
        meta: { clone: true },
        cbSuccess: (res: any) => {
          addFromResponse(res);
          notification.success({
            message: t("notification.cloneTemplateSuccess"),
          });
        },
      })
    );
  };

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 10;

      if (isNearBottom) {
        if (pagination.page <= items.meta?.totalPages) {
          setScrollFetch(true);
          dispatch(
            RequestAppAction.fetchTemplate({
              id: currentDidId,
              meta: pagination,
              cbSuccess: (data: any) => {
                setItems((pre: any) => ({
                  ...pre,
                  items: [...items.items, ...data?.data?.items],
                }));
                setScrollFetch(false);
                setPagination((pre) => ({ ...pre, page: pagination.page + 1 }));
              },
              cbFailure: (e: string) => {
                notification.error({ message: e });
              },
            })
          );
        }
      }
    }
  };

  const onSave = async (values: any, id: any) => {
    try {
      await form.validateFields();
      const data = {
        content: values.content,
        title: values.title,
      };
      dispatch(
        RequestAppAction.postTemplate({
          data: data,
          id: id,
          cbSuccess: (res: any) => {
            addFromResponse(res);
            notification.success({
              message: t("notification.success"),
            });
            modalRef.current?.closeModal();
          },
          cbFailure: (e: string) => {
            notification.error({ message: e });
          },
        })
      );
    } catch (errorInfo: any) {
      notification.error({ message: errorInfo });
    }
  };

  const onUpdate = async (values: any, id: any) => {
    try {
      await form.validateFields();
      const data = {
        content: values.content,
        title: values.title,
      };
      dispatch(
        RequestAppAction.putTemplate({
          data: data,
          id: id,
          cbSuccess: () => {
            fetchNewData();
            notification.success({
              message: t("notification.success"),
            });
            modalRef.current?.closeModal();
          },
          cbFailure: (e: string) => {
            notification.error({ message: e });
          },
        })
      );
    } catch (errorInfo: any) {
      notification.error({ message: errorInfo });
    }
  };

  useEffect(() => {
    const listInnerElement: any = scrollRef?.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [items, pagination.page]);

  return (
    <SettingsLayout classname="templates-page">
      <Space direction="vertical" size={40} style={{ width: "100%" }}>
        <Card
          ref={scrollRef}
          style={{
            padding: "1rem",
            height: "25rem",
            overflowY: "auto",
          }}
          className="templates-card"
        >
          <Spin
            spinning={isLoading || isFetching || isDeleteing || isPosting}
          >
            {items && items?.items?.length > 0 ? (
              items?.items.map((i: any, index: any) => (
                <>
                  <Card
                    key={index}
                    className="template-card-shadow"
                    style={{ marginTop: index !== 0 ? "1rem" : "0rem" }}
                  >
                    <Space className={`${width > 475 ? "d-flex align-items-center justify-content-between" : "d-flex-column align-items-start justify-content-center"}`}>
                      <div>{i.title}</div>
                      <Space size={width > 768 ? 20 : width > 576 ? 15 : 8}>
                        <Button
                          key={"button-clone" + index}
                          name="clone"
                          htmlType="button"
                          icon={<CopyOutlined />}
                          style={{ borderRadius: 50 }}
                          onClick={() => cloneTemplate(i)}
                        />

                        <Button
                          key={"button-edit" + index}
                          name="edit"
                          htmlType="button"
                          icon={<EditOutlined />}
                          style={{ borderRadius: 50 }}
                          onClick={() => edit(i)}
                        />
                        <Popconfirm
                          key={"button-delete" + index}
                          title="Delete the task"
                          description={t("settings.confirm")}
                          onConfirm={() => dele(i)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            key={"delete" + index}
                            name="delete"
                            htmlType="button"
                            icon={<DeleteOutlined />}
                            style={{ borderRadius: 50 }}
                          />
                        </Popconfirm>
                      </Space>
                    </Space>
                  </Card>
                  {index === items?.items?.length - 1 && scrollFetch && (
                    <Spin spinning> </Spin>
                  )}
                </>
              ))
            ) : (
              <div>{t("settings.noDataFetched")}</div>
            )}
          </Spin>
        </Card>
        <Space>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => add()}
          >
            {t("button.newTemplate")}
          </Button>
        </Space>
      </Space>
      <div>{contextHolderDel}</div>
      <div>
        <AddTemplateModal
          isEdit={isEdit}
          ref={modalRef}
          dId={currentDidId}
          form={form}
          onSave={onSave}
          onUpdate={onUpdate}
        />
      </div>
    </SettingsLayout>
  );
};

export default Templates;
