import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  deleteScheduleMessageFailure,
  deleteScheduleMessageSuccess,
} from "../slices/features/postScheduleMessage";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* deleteScheduleMessageSaga(action: any) {
  const { payload } = action;
  const { id } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.deleteScheduleMessage,
      baseUrl,
      id
    );

    yield put(deleteScheduleMessageSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(deleteScheduleMessageFailure({ statusCode, statusText }));
  }
}

export function* watchDeleteScheduleMessage() {
  yield takeLatest(
    RequestTypes.DELETE_SCHEDULE_MSG_REQUEST,
    deleteScheduleMessageSaga
  );
}
