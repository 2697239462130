import { createSelector } from "reselect";

const notesSelector = (state: TReduxState) => state.features.postNotes;

export const getBaseUrl = createSelector(notesSelector, (app) => app.baseUrl);

export const getPostNotesData = createSelector(
  notesSelector,
  (app) => app.apiStatus.data
);

export const getPostNotesState = createSelector(
  notesSelector,
  (app) => app.note
);

export const getPostNoteLoading = createSelector(
  getPostNotesState,
  (states) => states?.isLoading
);

export const getPutNoteLoading = createSelector(
  getPostNotesState,
  (states) => states?.isLoading
);

export const getDeleteNoteLoading = createSelector(
  getPostNotesState,
  (states) => states?.isLoading
);
