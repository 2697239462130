import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";

import {
  fetchDeleteGroupContactFailure,
  fetchDeleteGroupContactSuccess,
} from "../slices/features/deleteContactGroup";
import { RequestTypes } from "../types";

const appService = new AppService();

function* deleteGroupContactSaga(action: any) {
  const { payload } = action;
  const { id, groupId, contactId } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.deleteGroupContact,
      baseUrl,
      id,
      groupId,
      contactId
    );
    if (response) {
      yield put(fetchDeleteGroupContactSuccess({ ...response }));
      payload?.cbSuccess && payload?.cbSuccess({ ...response });
    }
  } catch (error: any) {
    const { statusCode, statusText } = error;
    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);
    yield put(fetchDeleteGroupContactFailure({ statusCode, statusText }));
  }
}

export function* watchDeleteGroupContact() {
  yield takeLatest(RequestTypes.DELETE_GROUP_CONTACT, deleteGroupContactSaga);
}
