import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  groupsState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
  downloadApiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState: INITIAL_STATE,
  reducers: {
    fetchGroupsData: (state) => {
      state.groupsState.isLoading = true;
    },
    fetchGroupsDataSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      groupsState: {
        ...state.groupsState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchGroupsDataFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      groupsState: {
        ...state.groupsState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearGroupData: (state) => ({
      ...state,
      groupsState: {
        ...state.groupsState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: false,
      },
    }),
    fetchDownloadGroupMessages: (state) => {
      state.groupsState.isLoading = true;
    },
    fetchDownloadGroupMessagesSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      groupsState: {
        ...state.groupsState,
        error: null,
        isLoading: false,
      },
      downloadApiStatus: {
        ...state.downloadApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchDownloadGroupMessagesFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      groupsState: {
        ...state.groupsState,
        error: action.payload?.message,
        isLoading: false,
      },
      downloadApiStatus: {
        ...state.downloadApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  fetchGroupsData,
  fetchGroupsDataSuccess,
  fetchGroupsDataFailure,
  clearGroupData,
  fetchDownloadGroupMessages,
  fetchDownloadGroupMessagesFailure,
  fetchDownloadGroupMessagesSuccess,
} = groupsSlice.actions;
export const groupsReducer = groupsSlice.reducer;
