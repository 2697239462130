import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const messageSelector = (state: TReduxState) => state.features.message;

export const getBaseUrl = createSelector(messageSelector, (app) => app.baseUrl);

export const getMessageData = createSelector(
  messageSelector,
  (app) => app.apiStatus.data
);

export const getMessageStates = createSelector(
  messageSelector,
  (app) => app.messageState
);

export const getMessageLoadingState = createSelector(
  getMessageStates,
  (state) => state?.isLoading
);
