import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchDidNotificationFailure,
  fetchDidNotificationSuccess,
} from "../slices/features/didNotification";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* didNotificationSaga(action: any) {
  const { payload } = action;
  const { id, meta } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchConversations,
      baseUrl,
      id,
      meta
    );

    yield put(
      fetchDidNotificationSuccess({
        data: response.data?.meta?.totalUnread,
        did: id,
      })
    );

    payload?.cbSuccess &&
      payload?.cbSuccess({ data: response.data?.meta?.totalUnread, did: id });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchDidNotificationFailure({ statusCode, statusText }));
  }
}

export function* watchDidNotification() {
  yield takeLatest(RequestTypes.DID_NOTIFICATION_REQUEST, didNotificationSaga);
}
