import { createSelector } from "reselect";

const holidayResponderSelector = (state: TReduxState) =>
  state.features.holidayResponder;

export const getBaseUrl = createSelector(
  holidayResponderSelector,
  (app) => app.baseUrl
);

export const getHolidayResponderData = createSelector(
  holidayResponderSelector,
  (app) => app.apiStatus.data
);

export const getPostHolidayResponderData = createSelector(
  holidayResponderSelector,
  (app) => app.postApiStatus.data
);

export const getHolidayResponderState = createSelector(
  holidayResponderSelector,
  (app) => app.holidayResponderState
);

export const getHolidayResponderLoading = createSelector(
  getHolidayResponderState,
  (states) => states?.isLoading
);
