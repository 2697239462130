import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  scheduleMessage: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const PostScheduleMessageSlice = createSlice({
  name: "postScheduleMessage",
  initialState: INITIAL_STATE,
  reducers: {
    postScheduleMessageData: (state) => {
      state.scheduleMessage.isLoading = true;
    },
    postScheduleMessageSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      scheduleMessage: {
        ...state.scheduleMessage,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    postScheduleMessageFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      scheduleMessage: {
        ...state.scheduleMessage,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    putScheduleMessageData: (state) => {
      state.scheduleMessage.isLoading = true;
    },
    putScheduleMessageSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      scheduleMessage: {
        ...state.scheduleMessage,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    putScheduleMessageFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      scheduleMessage: {
        ...state.scheduleMessage,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    deleteScheduleMessageData: (state) => {
      state.scheduleMessage.isLoading = true;
    },
    deleteScheduleMessageSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      scheduleMessage: {
        ...state.scheduleMessage,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    deleteScheduleMessageFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      scheduleMessage: {
        ...state.scheduleMessage,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    postBulkScheduleMessage: (state) => {
      state.scheduleMessage.isLoading = true;
    },
    postBulkScheduleMessageSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      scheduleMessage: {
        ...state.scheduleMessage,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    postBulkScheduleMessageFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      scheduleMessage: {
        ...state.scheduleMessage,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearScheduleMessage: (state) => ({
      ...state,
      scheduleMessage: {
        ...state.scheduleMessage,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: null,
      },
    }),
  },
});

export const {
  postScheduleMessageData,
  postScheduleMessageFailure,
  postScheduleMessageSuccess,
  putScheduleMessageData,
  putScheduleMessageFailure,
  putScheduleMessageSuccess,
  deleteScheduleMessageData,
  deleteScheduleMessageFailure,
  deleteScheduleMessageSuccess,
  postBulkScheduleMessage,
  postBulkScheduleMessageFailure,
  postBulkScheduleMessageSuccess,
} = PostScheduleMessageSlice.actions;
export const PostScheduleMessageReducer = PostScheduleMessageSlice.reducer;
