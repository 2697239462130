import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchUploadContactsFailure,
  fetchUploadContactsSuccess,
} from "../slices/features/uploadContacts";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* uploadContactsSaga(action: any) {
  const { payload } = action;
  const { id, data } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);
    const response: AxiosResponse<any> = yield call(
      appservice.uploadContact,
      baseUrl,
      id,
      data
    );
    yield put(fetchUploadContactsSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchUploadContactsFailure({ statusCode, statusText }));
  }
}

export function* watchUploadContacts() {
  yield takeLatest(RequestTypes.IMPORT_CONTACT_REQUEST, uploadContactsSaga);
}
