import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  PostMessageFailure,
  PostMessageSuccess,
} from "../slices/features/message";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* postMessage(action: any) {
  const { payload } = action;
  const { didId, id, data } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.postMessage,
      baseUrl,
      id,
      didId,
      data
    );

    payload?.cbSuccess && payload?.cbSuccess({ ...response });

    yield put(PostMessageSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(PostMessageFailure({ statusCode, statusText }));
  }
}

export function* watchPostMessage() {
  yield takeLatest(RequestTypes.SEND_MESSAGE_REQUEST, postMessage);
}
