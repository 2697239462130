import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ROLES } from "src/constants/roles";
import { AuthService } from "src/services/auth";
import { LocalStorageService } from "src/services/local-storage";
import { getBaseUrl } from "../selectors/features/app";
import { clearLoggedInUser } from "../slices/features/LoggedInUser";
import {
  fetchLoginDataFailure,
  fetchLoginDataSuccess,
} from "../slices/features/auth";
import { clearAutoResponder } from "../slices/features/autoResponder";
import { clearKeywords } from "../slices/features/autoresponderKeyword";
import { clearContacts } from "../slices/features/contacts";
import { clearConversation } from "../slices/features/conversations";
import { clearConversationById } from "../slices/features/conversationsById";
import { clearCurrentDid } from "../slices/features/currentDid";
import { clearContactUser } from "../slices/features/currentUserContact";
import { clearConversationUser } from "../slices/features/currentUserConversation";
import { clearDid } from "../slices/features/fetchDids";
import { clearGroupContacts } from "../slices/features/groupContacts";
import { clearGroupMessages } from "../slices/features/groupMessages";
import { clearGroupData } from "../slices/features/groups";
import { clearNotes } from "../slices/features/notes";
import { clearPrefrences } from "../slices/features/preference";
import { clearScheduleMessage } from "../slices/features/scheduleMessages";
import { clearSelectedGroup } from "../slices/features/selectedGroup";
import { clearTemplate } from "../slices/features/template";
import { clearFiles } from "../slices/features/uploadFiles";
import { clearUser } from "../slices/features/user";
import { clearWorkingDays } from "../slices/features/workingDays";
import { RequestTypes } from "../types";

const authService = new AuthService();
const localStorageService = new LocalStorageService();

function* fetchLogin(action: any) {
  const { payload } = action;
  const { Email, password, t } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const data = {
      username: Email,
      password: password,
    };

    yield put(clearAutoResponder());
    yield put(clearContactUser());
    yield put(clearContacts());
    yield put(clearConversation());
    yield put(clearConversationById());
    yield put(clearConversationUser());
    yield put(clearDid());
    yield put(clearCurrentDid());
    yield put(clearFiles());
    yield put(clearGroupContacts());
    yield put(clearGroupData());
    yield put(clearGroupMessages());
    yield put(clearKeywords());
    yield put(clearWorkingDays());
    yield put(clearUser());
    yield put(clearSelectedGroup());
    yield put(clearTemplate());
    yield put(clearScheduleMessage());
    yield put(clearPrefrences());
    yield put(clearNotes());
    yield put(clearLoggedInUser());

    const response: AxiosResponse<any> = yield call(
      authService.signIn,
      baseUrl,
      data
    );

    if (response?.data?.role === ROLES.USER) {
      yield call(
        localStorageService.persist,
        "user",
        JSON.stringify(response?.data)
      );
      yield call(
        localStorageService.persist,
        "userData",
        JSON.stringify(response)
      );
      yield call(
        localStorageService.persist,
        "authToken",
        response?.data?.jwtToken
      );

      yield put(fetchLoginDataSuccess({ ...response }));
    } else {
      toast.error(t("notification.notAuthorized"));
    }
  } catch (error: any) {
    const { statusCode, statusText } = error;
    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchLoginDataFailure({ statusCode, statusText, message }));
  }
}

export function* watchLogin() {
  yield takeLatest(RequestTypes.LOGIN_REQUEST, fetchLogin);
}
