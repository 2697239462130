import { createSelector } from "reselect";

const autoresponderKeywordSelector = (state: TReduxState) =>
  state.features.autoresponderKeyword;

export const getBaseUrl = createSelector(
  autoresponderKeywordSelector,
  (app) => app.baseUrl
);

export const getAutoresponderKeywordData = createSelector(
  autoresponderKeywordSelector,
  (app) => app.apiStatus.data
);

export const getAutoresponderKeywordState = createSelector(
  autoresponderKeywordSelector,
  (app) => app.autoresponderKeyword
);

export const getAutoresponderKeywordLoading = createSelector(
  getAutoresponderKeywordState,
  (states) => states?.isLoading
);
