import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  uploadContactsState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const uploadContactsSlice = createSlice({
  name: "uploadContacts",
  initialState: INITIAL_STATE,
  reducers: {
    fetchUploadContacts: (state) => {
      state.uploadContactsState.isLoading = true;
    },
    fetchUploadContactsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      uploadContactsState: {
        ...state.uploadContactsState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchUploadContactsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      uploadContactsState: {
        ...state.uploadContactsState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchDownloadContacts: (state) => {
      state.uploadContactsState.isLoading = true;
    },
    fetchDownloadContactsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      uploadContactsState: {
        ...state.uploadContactsState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchDownloadContactsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      uploadContactsState: {
        ...state.uploadContactsState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchDownloadConversation: (state) => {
      state.uploadContactsState.isLoading = true;
    },
    fetchDownloadConversationSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      uploadContactsState: {
        ...state.uploadContactsState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchDownloadConversationFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      uploadContactsState: {
        ...state.uploadContactsState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  fetchUploadContacts,
  fetchUploadContactsFailure,
  fetchUploadContactsSuccess,
  fetchDownloadContacts,
  fetchDownloadContactsFailure,
  fetchDownloadContactsSuccess,
  fetchDownloadConversation,
  fetchDownloadConversationFailure,
  fetchDownloadConversationSuccess,
} = uploadContactsSlice.actions;
export const uploadContactsReducer = uploadContactsSlice.reducer;
