import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchDownloadContactsFailure,
  fetchDownloadContactsSuccess,
} from "../slices/features/uploadContacts";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* downloadContactsSaga(action: any) {
  const { payload } = action;
  const { id } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);
    const response: AxiosResponse<any> = yield call(
      appservice.downloadContact,
      baseUrl,
      id
    );
    yield put(fetchDownloadContactsSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchDownloadContactsFailure({ statusCode, statusText }));
  }
}

export function* watchDownloadContacts() {
  yield takeLatest(RequestTypes.EXPORT_CONTACT_REQUEST, downloadContactsSaga);
}
