import React from "react";
import { Row } from "antd";
import "./auto-responder.scss";

const AutoResponderLayout = ({
  classname = "",
  children,
}: {
  classname?: string,
  children: React.ReactNode,
}) => {
  return (
    <div className="auto-responder-page">
      <Row
        style={{ width: "100%" }}
        className={`${classname} auto-responder-container`}
      >
        {children}
      </Row>
    </div>
  );
};

export default AutoResponderLayout;
