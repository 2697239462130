import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  event: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: {
      items: [],
    },
  },
};

export const EventsSlice = createSlice({
  name: "events",
  initialState: INITIAL_STATE,
  reducers: {
    fetchEventsData: (state) => {
      state.event.isLoading = true;
    },
    fetchEventsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      event: {
        ...state.event,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchEventsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      event: {
        ...state.event,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearEvents: (state) => ({
      ...state,
      event: {
        ...state.event,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: {
          items: [],
        },
      },
    }),
  },
});

export const {
  fetchEventsData,
  fetchEventsFailure,
  fetchEventsSuccess,
  clearEvents,
} = EventsSlice.actions;
export const EventsReducer = EventsSlice.reducer;
