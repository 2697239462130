import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  currentGroup: null,
};

const selectedGroupSlice = createSlice({
  name: "selectedGroup",
  initialState,
  reducers: {
    selectGroup: (state, action: PayloadAction<any>) => {
      state.currentGroup = action.payload;
    },
    clearSelectedGroup: (state) => {
      state.currentGroup = null;
    },
  },
});

export const { selectGroup, clearSelectedGroup } = selectedGroupSlice.actions;
export const selectedGroupReducer = selectedGroupSlice.reducer;
