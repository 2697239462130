export const PAGINATION = {
  page: 1,
  limit: 10,
};

export const maxSize = 5 * 1024 * 1024;

export const PhoneNumberRegex = /^\+?1?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const PASSWORDREGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,30}$/;
