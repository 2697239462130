import {
  CloseOutlined,
  DownloadOutlined,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { Flex, Modal, Space, Typography, notification } from "antd";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PAGINATION } from "src/constants/common";
import ENDPOINTS from "src/constants/endpoints";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import { getUser } from "src/store/selectors/features/user";
import RequestAppAction from "src/store/slices/appActions";
import Colors from "src/themes/colors";

const { Title, Text } = Typography;

interface modalProps {
  setErrorData: (data: any[]) => void;
}

const AttachContacts = forwardRef(({ setErrorData }: modalProps, ref: any) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const did = useSelector(getCurrentDidId);
  const uploadRef = useRef<any>(null);
  const [file, setFile] = useState<any>([]);
  const user: any = useSelector(getUser);

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setShow(false);
    },
    openModal: () => {
      setShow(true);
    },
  }));

  function handleFileSelect(e: any) {
    const files = e.target.files || e.dataTransfer.files;
    if (files) {
      const fileList = Object.keys(files).map((file) => files[file]);
      if (fileList.length > 0) {
        setFile(fileList);
      }
    }
  }

  const fileExtensionValidation = () => {
    const fileExtension = file[0].name.split(".").pop();
    if (fileExtension !== "csv") {
      notification.error({ message: t("notification.fileExtensionError") });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (file.length > 0) {
      if (!fileExtensionValidation) {
        return setFile([]);
      }

      const formData = new FormData();
      formData.append("file", file[0]);

      dispatch(
        RequestAppAction.uploadContacts({
          id: did,
          data: formData,
          cbSuccess: () => {
            dispatch(
              RequestAppAction.fetchContacts({
                id: did,
                meta: PAGINATION,

                cbFailure: (e: string) => {
                  notification.error({ message: e });
                },
              })
            );
            notification.success({ message: t("notification.contactsImport") });
            setShow(false);
            setFile([]);
          },
          cbFailure: (e: { row: number; errors: string[] }[]) => {
            dispatch(
              RequestAppAction.fetchContacts({
                id: did,
                meta: PAGINATION,
              })
            );
            setErrorData(e);
            setShow(false);
            setFile([]);
          },
        })
      );
    }
  }, [file]);

  const onImport = () => {
    uploadRef.current?.click();
  };

  const onExport = () => {
    dispatch(
      RequestAppAction.downloadContacts({
        id: did,
        cbSuccess: (blob: any) => {
          const url = window.URL.createObjectURL(new Blob([blob.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Export_${did}_${new Date().getTime()}.csv`
          );

          document.body.appendChild(link);

          link.click();
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }

          setShow(false);
        },
        cbFailure: (e: string) => {
          notification.error({ message: e });
        },
      })
    );
  };

  return (
    <Modal
      centered
      destroyOnClose
      open={show}
      okButtonProps={{ htmlType: "submit" }}
      closeIcon={
        <CloseOutlined
          onClick={() => {
            setShow(false);
          }}
        />
      }
      title={
        <Title className="font-size-24 font-weight-500">
          {t("heading.contacts")}
        </Title>
      }
      className="import-contact-modal"
      footer={false}
    >
      <input
        ref={uploadRef}
        type="file"
        accept=".csv"
        onChange={handleFileSelect}
        style={{ display: "none" }}
      />
      <Flex justify="space-around" align="center">
        <Space onClick={onImport} className="cursor-pointer">
          <div
            style={{
              padding: "1.8rem 2.5rem 1.8rem 2.5rem",
              background: Colors.Secondary,
              borderRadius: "0.2rem",
            }}
          >
            <Space direction="vertical" align="center" content="center">
              <ImportOutlined
                style={{ fontSize: "2rem", color: Colors.white }}
              />
              <Text className="font-size-16 text-white font-weight-700">
                {t("heading.import")}
              </Text>
            </Space>
          </div>
        </Space>
        <Space onClick={onExport} className="cursor-pointer">
          <div
            style={{
              padding: "1.8rem 2.5rem 1.8rem 2.5rem",
              background: Colors.Secondary,
              borderRadius: "0.2rem",
            }}
          >
            <Space direction="vertical" align="center" content="center">
              <ExportOutlined
                style={{ fontSize: "2rem", color: Colors.white }}
              />
              <Text className="font-size-16 text-white font-white font-weight-700">
                {t("heading.export")}
              </Text>
            </Space>
          </div>
        </Space>
      </Flex>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "2rem",
        }}
      >
        <span style={{ marginRight: "0.3rem" }}>
          <a
            href={ENDPOINTS.SAMPLECSV}
            className="font-size-16  font-weight-700"
          >
            <DownloadOutlined
              style={{ marginRight: "0.25rem", marginTop: "-0.2rem" }}
            />
            {t("heading.download")}
          </a>
        </span>
        <Text className="font-size-16  font-weight-700">
          {t("heading.clickHereToDownloadSampleFile")}
        </Text>
      </div>
    </Modal>
  );
});

export default AttachContacts;
