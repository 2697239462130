import "./modal-button-container.scss";

const ModalButtonContainer = ({
  containerStyle,
  children,
}: {
  containerStyle?: any;
  children?: any,
}) => {
  return (
    <div
      className="d-flex position-absolute align-items-end justify-content-end"
      style={{ bottom: 40, right: 10, position: "absolute", ...containerStyle }}
    >
      {children}
    </div>
  );
};

export default ModalButtonContainer;
