import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  contacts: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
};

export const contactBulkSlice = createSlice({
  name: "contactBulk",
  initialState: INITIAL_STATE,
  reducers: {
    fetchBulkContacts: (state) => {
      state.contacts.isLoading = true;
    },
    fetchBulkContactsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      contacts: {
        ...state.contacts,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchBulkContactsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      contacts: {
        ...state.contacts,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  fetchBulkContacts,
  fetchBulkContactsSuccess,
  fetchBulkContactsFailure,
} = contactBulkSlice.actions;
export const contactBulkReducer = contactBulkSlice.reducer;
