import { createSelector } from "reselect";

const notesSelector = (state: TReduxState) => state.features.notes;

export const getBaseUrl = createSelector(notesSelector, (app) => app.baseUrl);

export const getNotesData = createSelector(
  notesSelector,
  (app) => app.apiStatus.data
);

export const getNotesState = createSelector(notesSelector, (app) => app.note);

export const getNoteLoading = createSelector(
  getNotesState,
  (states) => states?.isLoading
);
