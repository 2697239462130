import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  createGroupsState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
};

export const createGroupsSlice = createSlice({
  name: "createGroups",
  initialState: INITIAL_STATE,
  reducers: {
    fetchCreateGroup: (state) => {
      state.createGroupsState.isLoading = true;
    },
    fetchCreateGroupSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      createGroupsState: {
        ...state.createGroupsState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchCreateGroupFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      createGroupsState: {
        ...state.createGroupsState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchChangeGroupName: (state) => {
      state.createGroupsState.isLoading = true;
    },
    fetchChangeGroupNameSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      createGroupsState: {
        ...state.createGroupsState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchChangeGroupNameFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      createGroupsState: {
        ...state.createGroupsState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  fetchCreateGroup,
  fetchCreateGroupFailure,
  fetchCreateGroupSuccess,
  fetchChangeGroupName,
  fetchChangeGroupNameFailure,
  fetchChangeGroupNameSuccess,
} = createGroupsSlice.actions;
export const createGroupsReducer = createGroupsSlice.reducer;
