import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Popconfirm,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PAGINATION } from "src/constants/common";
import { getScheduleMessageData } from "src/store/selectors/features/scheduleMessage";
import RequestAppAction from "src/store/slices/appActions";
import { clearScheduleMessage } from "src/store/slices/features/scheduleMessages";
import { AddScheduleMessageModal } from "../listing-sidebar/addScheduleMessageModal";

interface modalProps {
  openModal: (text: string) => void;
  closeModal: () => void;
}

const { Text } = Typography;

export const ConfirmModalPartial = (props: any) => {
  const { contact } = props;
  const modalRef = useRef<modalProps>();
  const dispatch = useDispatch();
  const scheduleMessages: any = useSelector(getScheduleMessageData);
  const [pagination, setPagination] = useState(PAGINATION);
  const [items, setItems] = useState<any>([]);
  const [scrollFetch, setScrollFetch] = useState(false);
  const [form] = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const scrollRef: any = useRef(null);
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  const addScheduleMessageModal = (i: any) => {
    setIsEdit(false);
    setAttachments([]);
    form.resetFields();
    setTimeout(() => {
      modalRef.current?.openModal(
        t("common.newScheduledMessagefor", {
          name: i?.firstName || i?.phoneNumber || "",
        })
      );
    }, 100);
  };
  const editScheduleMessageModal = (i: any) => {
    const charactersLength = i?.content?.length;

    setIsEdit(true);

    form.setFieldsValue({
      content: i.content,
      id: i.id,
      scheduledTime: dayjs(i.scheduledTime),
      length: charactersLength,
    });

    setTimeout(() => {
      modalRef.current?.openModal(`Edit Scheduled Message`);
    }, 200);
  };

  const fetchNewData = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
    dispatch(
      RequestAppAction.fetchScheduleMessage({
        id: contact?.id,
        meta: PAGINATION,
        cbSuccess: () => {
          setInitialLoad(false);
          setPagination((pre: any) => ({
            ...pre,
            page: PAGINATION.page + 1,
          }));
        },
        cbFailure: (e: string) => {
          setInitialLoad(false);
          notification.error({ message: e });
        },
      })
    );
  };

  useEffect(() => {
    if (contact?.id) {
      fetchNewData();
    }
  }, [contact?.id]);

  const confirmDelete = (messageId: any) => {
    dispatch(
      RequestAppAction.deleteSchdeuleMessage({
        id: messageId,
        cbSuccess: () => {
          setItems((pre: any) => ({
            ...pre,
            items: items?.items.filter((i: any) => i.id !== messageId),
          }));
          notification.warning({
            message: t("notification.deleteScheduledSuccess"),
          });
          setPagination({ ...PAGINATION });
        },
      })
    );
  };

  useEffect(() => {
    if (scrollFetch) {
      const handler = setTimeout(() => {
        dispatch(
          RequestAppAction.fetchScheduleMessage({
            id: contact?.id,
            meta: pagination,
            cbSuccess: (data: any) => {
              setItems((pre: any) => ({
                ...pre,
                items: [...items.items, ...data?.data?.items],
              }));

              setPagination((pre: any) => ({
                ...pre,
                page: pagination.page + 1,
              }));

              setScrollFetch(false);
            },
            cbFailure: (e: string) => {
              setScrollFetch(false);
              notification.error({ message: e });
            },
          })
        );
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [scrollFetch]);

  useEffect(() => {
    if (scheduleMessages && !scrollFetch) {
      setItems({ ...scheduleMessages, items: [...scheduleMessages?.items] });
    }
  }, [scheduleMessages, scrollRef.current]);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 10;

      if (isNearBottom) {
        if (pagination.page <= scheduleMessages?.meta?.totalPages) {
          setScrollFetch(true);
        }
      }
    }
  };

  useEffect(() => {
    const listInnerElement: any = scrollRef?.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [scrollRef.current?.scrollHeight, scheduleMessages]);

  useEffect(() => {
    return () => {
      setInitialLoad(true);
      dispatch(clearScheduleMessage());
      setPagination((pre: any) => ({
        ...pre,
        page: PAGINATION.page + 1,
      }));
    };
  }, []);

  return (
    <div>
      <div>
        <div style={{ height: "25rem", overflowY: "auto" }} ref={scrollRef}>
          {items && items?.items?.length > 0 ? (
            items?.items?.map((scheduleMessage: any, _index: any) => (
              <div key={_index}>
                <Card
                  className="card-shadow"
                  style={{
                    width: "97%",
                    borderRadius: "0.5rem",
                    marginBottom: 40,
                    marginLeft: "0.3rem",
                    marginTop: 20,
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Title level={5} className="font-size-16 font-weight-500">
                        {new Date(
                          scheduleMessage.scheduledTime
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}{" "}
                        @{" "}
                        {new Date(
                          scheduleMessage.scheduledTime
                        ).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}
                      </Title>
                      <p className="font-size-14 font-weight-400">
                        {scheduleMessage.content}
                      </p>
                    </div>
                    <div>
                      <Space>
                        <Popconfirm
                          title="Delete the task"
                          description="Are you sure to delete this Scheduled Message?"
                          onConfirm={() => confirmDelete(scheduleMessage.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            style={{ borderRadius: 50 }}
                          />
                        </Popconfirm>

                        <Button
                          onClick={() => {
                            setAttachments(
                              scheduleMessage?.fileLocations ?? []
                            );
                            editScheduleMessageModal(scheduleMessage);
                          }}
                          icon={<EditOutlined />}
                          style={{ borderRadius: 50 }}
                        />
                      </Space>
                    </div>
                  </div>
                </Card>
              </div>
            ))
          ) : !initialLoad ? (
            <div>{t("settings.noDataFetched")}</div>
          ) : (
            <Space
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin />
            </Space>
          )}
        </div>
      </div>

      <div>
        <Space className="d-flex justify-content-center">
          <Button
            onClick={() => addScheduleMessageModal(contact)}
            type="primary"
            icon={<PlusCircleOutlined />}
          >
            {t("listingContact.scheduledANewMessage")}
          </Button>
        </Space>
      </div>
      <AddScheduleMessageModal
        contactId={contact.id}
        isEdit={isEdit}
        modalRef={modalRef}
        form={form}
        images={attachments}
        setImages={setAttachments}
        fetchNewData={fetchNewData}
        setPagination={setPagination}
        variableData={{
          firstName: contact?.firstName,
        }}
      />
    </div>
  );
};
