const Colors = {
  white: "#FFFFFF",
  Black: "#000000",
  BlackLightOpacity: "rgba(0, 0, 0, 0.80)",
  White: "#FFFFFF",
  Tertiary: "#1C4DA1",
  transparentBlack: "#00000000",
  InactiveTertiary: "",
  Placeholder: "#B1B1B1",
  Gray: "#757575",
  TitleGray: "#909090",
  Secondary: "#F7941D",
  SecondaryLight: "#f5e6d4",
  darkSecondary: "#D17E1B",
  LightGray: "#D9D9D9",
  SelectedItemGrey: "#f4f4f4",
  GrayLight: "#FAFAFA",
  NeroColor: "#202020",
  red: "#E62814",
  AlertBackground: "#1C4DA1",
  DarkGray: "#3E3E3E",
  DarkCharcoal: "#333333",
  SecondaryColorInactive: "#FBCE98",
  LightGreen: "#e5f6dd",
  Green: "#52C41A",
  Red: "#FF4D4F",
  LightRed: "#ffe4e5",
  Blue: "#1c4da1",
  BgGray: "#F0F2F5",
  senderBg: "#FCD4A5",
  reciverBg: "#D2DBEC",
  BgListing: "#F5F5F5",
  Label: "#A1A1A1",
  IconBackground: "#F2F2F2",
  IconColor: "#333333"
};

export default Colors;
