import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  holidayResponderState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
  postApiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
};

export const holidayResponderSlice = createSlice({
  name: "autoResponderHoliday",
  initialState: INITIAL_STATE,
  reducers: {
    postHolidayResponder: (state) => {
      state.holidayResponderState.isLoading = true;
    },
    postHolidayResponderSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      holidayResponderState: {
        ...state.holidayResponderState,
        error: null,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    postHolidayResponderFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      holidayResponderState: {
        ...state.holidayResponderState,
        error: action.payload?.message,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchHolidayResponder: (state) => {
      state.holidayResponderState.isLoading = true;
    },
    fetchHolidayResponderSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      holidayResponderState: {
        ...state.holidayResponderState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchHolidayResponderFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      holidayResponderState: {
        ...state.holidayResponderState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    deleteHolidayResponder: (state) => {
      state.holidayResponderState.isLoading = true;
    },
    deleteHolidayResponderSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      holidayResponderState: {
        ...state.holidayResponderState,
        error: null,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    deleteHolidayResponderFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      holidayResponderState: {
        ...state.holidayResponderState,
        error: action.payload?.message,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    updateHolidayResponder: (state) => {
      state.holidayResponderState.isLoading = true;
    },
    updateHolidayResponderSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      holidayResponderState: {
        ...state.holidayResponderState,
        error: null,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    updateHolidayResponderFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      holidayResponderState: {
        ...state.holidayResponderState,
        error: action.payload?.message,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearVacation: (state) => ({
      ...state,
      holidayResponderState: {
        ...state.holidayResponderState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: false,
      },
    }),
  },
});

export const {
  postHolidayResponder,
  postHolidayResponderSuccess,
  postHolidayResponderFailure,
  fetchHolidayResponder,
  fetchHolidayResponderFailure,
  fetchHolidayResponderSuccess,
  deleteHolidayResponder,
  deleteHolidayResponderFailure,
  deleteHolidayResponderSuccess,
  updateHolidayResponder,
  updateHolidayResponderFailure,
  updateHolidayResponderSuccess,
  clearVacation,
} = holidayResponderSlice.actions;
export const holidayResponderReducer = holidayResponderSlice.reducer;
