import { Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "src/components/header";
import styles from "./styles.module.scss";

const { Text } = Typography;

const TokenExpire: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles.login_form_container}>
      <Space
        onClick={() => navigate("/")}
        align="center"
        className={styles.login_header}
      >
        <Header />
      </Space>
      <Space direction="vertical" className={styles.validate_div}>
        <Text className={styles.check_mail_text}>
          {t("heading.tokenExpired")}
        </Text>
        <Text className={styles.parah}>{t("heading.linkExpired")}</Text>
        <Text className={styles.parah}>
          {t("heading.description1")}{" "}
          <Text
            onClick={() => {
              navigate("/login");
            }}
            className={styles.underline_text}
          >
            {t("heading.resetPassword")}
          </Text>{" "}
          {t("heading.description2")}
        </Text>
      </Space>
    </div>
  );
};

export default TokenExpire;
