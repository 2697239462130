import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const loggedInUserSelector = (state: TReduxState) =>
  state.features.LoggedInUser;

export const getBaseUrl = createSelector(
  loggedInUserSelector,
  (app) => app.baseUrl
);

export const getLoggedInUserData = createSelector(
  loggedInUserSelector,
  (app) => app.apiStatus.data
);

export const getLoggedInUserStates = createSelector(
  loggedInUserSelector,
  (app) => app.loggedInUserState
);

export const getSignoutLoadingState = createSelector(
  getLoggedInUserStates,
  (state) => state?.isLoading
);
