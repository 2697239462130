import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  user: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  putApiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    getUserData: (state) => {
      state.user.isLoading = true;
    },
    getUserSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      user: {
        ...state.user,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    getUserFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      user: {
        ...state.user,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    putUserData: (state) => {
      state.user.isLoading = true;
    },
    putUserDataSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      user: {
        ...state.user,
        error: null,
        isLoading: false,
      },
      putApiStatus: {
        ...state.putApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    putUserDataFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      user: {
        ...state.user,
        error: action.payload?.message,
        isLoading: false,
      },
      putApiStatus: {
        ...state.putApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearUser: (state) => ({
      ...state,
      user: {
        ...state.user,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: null,
      },
    }),
  },
});

export const {
  getUserData,
  getUserFailure,
  getUserSuccess,
  putUserData,
  putUserDataFailure,
  putUserDataSuccess,
  clearUser,
} = userSlice.actions;

export const UserReducer = userSlice.reducer;
