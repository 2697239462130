import { Flex, Form, Input, Spin, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import RequestAppAction from "src/store/slices/appActions";
import Button from "../button";

const { Text } = Typography;

interface NoteProps {
  handleNotesLoading: boolean;
  ref?: any;
  editId: any;
  modalRef: any;
  userDetails: any;
  addFromResponse: any;
  onEditSuccess: any;
  editValue: any;
}

export const Note = ({
  handleNotesLoading,
  editId,
  modalRef,
  userDetails,
  onEditSuccess,
  addFromResponse,
  editValue,
}: NoteProps) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onFinishEdit = (val: any) => {
    const noteData = {
      content: val?.note,
      title: val?.title,
    };
    dispatch(
      RequestAppAction.putNote({
        data: noteData,
        id: editId,
        cbSuccess: (data: any) => {
          onEditSuccess(data);
          modalRef.current?.closeModal();
          form.resetFields();
        },
      })
    );
  };

  const onAddNote = (val: any) => {
    if (editId) {
      onFinishEdit(val);
    } else {
      const noteData = {
        content: val?.note,
      };

      dispatch(
        RequestAppAction.postNotes({
          data: noteData,
          id: userDetails?.id,
          cbSuccess: (data: any) => {
            addFromResponse(data);
            modalRef.current?.closeModal();
            form.resetFields();
          },
        })
      );
    }
  };

  useEffect(() => {
    if (editValue) {
      form.setFieldsValue({
        note: editValue?.content?.trim(),
        title: editValue?.title?.trim(),
      });
    } else {
      form.resetFields();
    }
  }, [editValue]);

  return (
    <Flex vertical>
      <Spin spinning={handleNotesLoading}>
        <Form onFinish={onAddNote} form={form} name="noteForm">
          <div>
            <Text className="font-size-14 font-weight-500">Note</Text>
            <Form.Item
              rules={[{ required: true, message: t("fields.noteError") }]}
              name="note"
            >
              <Input.TextArea
                placeholder={t("placeholder.enterNoteHere")}
                autoSize={{ minRows: 4, maxRows: 10 }}
                style={{ resize: "block" }}
              />
            </Form.Item>
          </div>
          <div className="row d-flex justify-content-between align-items-end">
            <div className="col-auto">
              <Button
                onBtnClick={() => {
                  modalRef?.current?.closeModal();
                }}
                title={t("button.cancel")}
                btnClass="white_btn"
              />
            </div>
            <div className="col-auto">
              <Button
                title={editId ? t("button.save") : t("button.addNote")}
                buttonType="submit"
              />
            </div>
          </div>
        </Form>
      </Spin>
    </Flex>
  );
};
