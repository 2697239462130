import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const loginSelector = (state: TReduxState) => state.features.login;

export const getBaseUrlLogin = createSelector(
  loginSelector,
  (app) => app.baseUrl
);

export const getLoginData = createSelector(
  loginSelector,
  (app: any) => app.userData
);

export const getLoginStates = createSelector(
  loginSelector,
  (app: any) => app.login
);

export const getLoginLoadingState = createSelector(
  getLoginStates,
  (loginStates) => loginStates?.isLoading
);
