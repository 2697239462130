import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";

import { BUILD_ENV } from "../constants/build-env";
import rootSaga from "./sagas";
import rootReducer from "./slices";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: {},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
  devTools: process.env.REACT_APP_ENV !== BUILD_ENV.PRODUCTION,
});

sagaMiddleware.run(rootSaga);

export type ReduxState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default () => {
  const persistor = persistStore(store);
  return { store, persistor };
};
