import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

const currentConversationUserSlice = createSlice({
  name: "currentConversationUser",
  initialState,
  reducers: {
    setConversationUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    clearConversationUser: (state) => {
      state.user = null;
    },
  },
});

export const { setConversationUser, clearConversationUser } =
  currentConversationUserSlice.actions;
export const ConversationUserReducer = currentConversationUserSlice.reducer;
