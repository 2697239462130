import { createSelector } from "reselect";

const eventsSelector = (state: TReduxState) => state.features.events;

export const getBaseUrl = createSelector(eventsSelector, (app) => app.baseUrl);

export const getEventsData = createSelector(
  eventsSelector,
  (app) => app.apiStatus.data
);

export const getEventsState = createSelector(eventsSelector, (app) => app.event);

export const getEventLoading = createSelector(
  getEventsState,
  (states) => states?.isLoading
);
