import { createSelector } from "reselect";

const uploadFilesSelector = (state: TReduxState) => state.features.uploadFiles;

export const getBaseUrl = createSelector(
  uploadFilesSelector,
  (app) => app.baseUrl
);

export const uploadFilesData = createSelector(
  uploadFilesSelector,
  (app) => app.apiStatus.data
);

export const uploadFilesState = createSelector(
  uploadFilesSelector,
  (app) => app.filesState
);

export const uploadFilesLoading = createSelector(
  uploadFilesState,
  (states) => states?.isLoading
);
