import { RequestTypes } from "../types";

class RequestAuthAction {
  static callLogin(payload: any) {
    return {
      type: RequestTypes.LOGIN_REQUEST,
      payload,
    };
  }
}
export default RequestAuthAction;
