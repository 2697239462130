import { AxiosResponse } from "axios";
import { takeEvery, call, select, put } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchAppDataFailure,
  fetchAppDataSuccess,
} from "../slices/features/app";

const appService = new AppService();

function* fetchApp() {
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.fetchAppData,
      baseUrl
    );

    yield put(fetchAppDataSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(fetchAppDataFailure({ statusCode, statusText }));
  }
}

export const appSagas = [takeEvery("app/fetchAppData", fetchApp)];
