import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  note: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const PostNotesSlice = createSlice({
  name: "postNotes",
  initialState: INITIAL_STATE,
  reducers: {
    postNoteData: (state) => {
      state.note.isLoading = true;
    },
    postNotesSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      note: {
        ...state.note,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    postNotesFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      note: {
        ...state.note,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    putNoteData: (state) => {
      state.note.isLoading = true;
    },
    putNotesSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      note: {
        ...state.note,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    putNotesFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      note: {
        ...state.note,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    deleteNoteData: (state) => {
      state.note.isLoading = true;
    },
    deleteNotesSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      note: {
        ...state.note,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    deleteNotesFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      note: {
        ...state.note,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  postNoteData,
  postNotesFailure,
  postNotesSuccess,
  putNoteData,
  putNotesFailure,
  putNotesSuccess,
  deleteNoteData,
  deleteNotesFailure,
  deleteNotesSuccess,
} = PostNotesSlice.actions;
export const PostNotesReducer = PostNotesSlice.reducer;
