import { LockFilled, MailFilled } from "@ant-design/icons";
import { Checkbox, Form, Input, Space, Spin, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "src/components/button";
import Header from "src/components/header";
import { LocalStorageService } from "src/services/local-storage";
import { getLoginLoadingState } from "src/store/selectors/features/auth";
import RequestAuthAction from "src/store/slices/actions";
import { fetchLoginDataSuccess } from "src/store/slices/features/auth";
import styles from "./login.module.scss";

const { Text } = Typography;

const localStorageService = new LocalStorageService();

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [remember, setRemember] = useState(false);
  const isLoading = useSelector(getLoginLoadingState);
  const { t } = useTranslation();

  const [userValues, setUserValues] = useState<{
    username: string;
    password: string;
  } | null>(null);

  const loggedIn = useSelector(
    (state: any) => state?.features?.login?.userData
  );

  const [form] = useForm();

  const checkLoggedIn = async () => {
    const token = await localStorageService.fetch("authToken");
    const userData = await localStorageService.fetch("userData");

    if (token && userData) {
      dispatch(fetchLoginDataSuccess(JSON.parse(userData)));
    }
  };

  useLayoutEffect(() => {
    checkLoggedIn();
  }, []);

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn]);

  const onFinish = (values: any) => {
    const { Email, password } = values;

    if (remember) {
      localStorageService.persist("rememberUser", JSON.stringify(values));
    } else {
      localStorageService.remove("rememberUser");
    }

    dispatch(
      RequestAuthAction.callLogin({
        Email,
        password,
        t,
        cbFailure: (e: string) => toast.error(e),
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    localStorageService.fetch("rememberUser").then((res: any) => {
      if (res) {
        const parsedData = JSON.parse(res);

        setUserValues({
          username: parsedData.Email,
          password: parsedData.password,
        });
      }
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      Email: userValues?.username?.trim(),
      password: userValues?.password?.trim(),
    });
  }, [userValues]);

  useEffect(() => {
    setLoading(loading);
  }, [isLoading]);

  return (
    <div className={styles.login_form_container}>
      <Space align="center" className={styles.login_header}>
        <Header />
      </Space>
      <Space direction="vertical" className={styles.login_form_wrapper}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          autoComplete="off"
          className={`login-form ${styles.login_form}`}
        >
          <Spin spinning={loading}>
            <Form.Item
              name="Email"
              label={t("fields.username")}
              rules={[
                { required: true, message: "Please, input your Username!" },
              ]}
            >
              <Input
                prefix={<MailFilled className={styles.site_form_item_icon} />}
                placeholder={t("fields.username")}
                className={styles.login_inputs}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("fields.password")}
              rules={[
                { required: true, message: "Please, input your Password!" },
              ]}
            >
              <Input
                prefix={<LockFilled className={styles.site_form_item_icon} />}
                type="password"
                placeholder={t("fields.password")}
                className={styles.login_inputs}
              />
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox
                value={remember}
                onChange={() => {
                  setRemember(!remember);
                }}
              >
                {t("checkBox.rememberMe")}
              </Checkbox>
            </Form.Item>
            <div className="my-4">
              <Text
                onClick={() => {
                  navigate("/forgot-password");
                }}
                className={styles.underline_text}
              >
                {t("underlineText.forgetPassowrd")}
              </Text>
            </div>

            <Form.Item>
              <Button
                title={t("button.login")}
                buttonType="submit"
                btnClass="full_btn"
                loading={loading}
              />
            </Form.Item>
          </Spin>
        </Form>
      </Space>
    </div>
  );
};

export default Login;
