import { createSelector } from "reselect";

const wrokingDaysSelector = (state: TReduxState) => state.features.workingDays;

export const getBaseUrl = createSelector(
  wrokingDaysSelector,
  (app) => app.baseUrl
);

export const getWorkingDaysData = createSelector(
  wrokingDaysSelector,
  (app) => app.apiStatus.data
);

export const getPostWorkingDaysData = createSelector(
  wrokingDaysSelector,
  (app) => app.postApiStatus.data
);

export const getWorkingDaysState = createSelector(
  wrokingDaysSelector,
  (app) => app.workingDayState
);

export const getpostHolidayResponderLoading = createSelector(
  getWorkingDaysState,
  (states) => states?.isLoading
);
