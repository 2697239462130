import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  putScheduleMessageFailure,
  putScheduleMessageSuccess,
} from "../slices/features/postScheduleMessage";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* putScheduleMessageSaga(action: any) {
  const { payload } = action;
  const { id, data } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.putScheduleMessage,
      baseUrl,
      id,
      data
    );

    yield put(putScheduleMessageSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;
    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(putScheduleMessageFailure({ statusCode, statusText }));
  }
}

export function* watchPutScheduleMessage() {
  yield takeLatest(
    RequestTypes.PUT_SCHEDULE_MSG_REQUEST,
    putScheduleMessageSaga
  );
}
