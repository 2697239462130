import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  groupMessagesState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
  postGroupMessagesState: {
    isLoading: false,
    error: null,
  },
  postApiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
};

export const groupMessagesSlice = createSlice({
  name: "groupMessages",
  initialState: INITIAL_STATE,
  reducers: {
    fetchGroupMessages: (state) => {
      state.groupMessagesState.isLoading = true;
    },
    fetchGroupMessagesSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      groupMessagesState: {
        ...state.groupMessagesState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchGroupMessagesFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      groupMessagesState: {
        ...state.groupMessagesState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    postGroupMessage: (state) => {
      state.postGroupMessagesState.isLoading = true;
    },
    postGroupMessageSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      postGroupMessagesState: {
        ...state.postGroupMessagesState,
        error: null,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    postGroupMessageFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      postGroupMessagesState: {
        ...state.postGroupMessagesState,
        error: action.payload?.message,
        isLoading: false,
      },
      postApiStatus: {
        ...state.postApiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearGroupMessages: (state) => ({
      ...state,
      groupMessagesState: {
        ...state.groupMessagesState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: false,
      },
    }),
  },
});

export const {
  fetchGroupMessages,
  fetchGroupMessagesFailure,
  fetchGroupMessagesSuccess,
  postGroupMessage,
  postGroupMessageFailure,
  postGroupMessageSuccess,
  clearGroupMessages,
} = groupMessagesSlice.actions;
export const groupMessagesReducer = groupMessagesSlice.reducer;
