import { createSelector } from "reselect";

const DidSelector = (state: TReduxState) => state.features.dids;

export const getBaseUrl = createSelector(DidSelector, (app) => app.baseUrl);

export const getDidData = createSelector(
  DidSelector,
  (app) => app.apiStatus.data
);

export const getDidState = createSelector(DidSelector, (app) => app.did);

export const getDidLoading = createSelector(
  getDidState,
  (states) => states?.isLoading
);
