import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  updateAutoResponderFailure,
  updateAutoResponderSuccess,
} from "../slices/features/autoResponder";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* putAutoresponderSaga(action: any) {
  const { payload } = action;
  const { id, data } = payload;
  try {
    const baseUrl: string = yield select(getBaseUrl);
    const response: AxiosResponse<any> = yield call(
      appservice.putAutoresponder,
      baseUrl,
      id,
      data
    );
    yield put(updateAutoResponderSuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(updateAutoResponderFailure({ statusCode, statusText }));
  }
}

export function* watchPutAutoResponder() {
  yield takeLatest(
    RequestTypes.PUT_AUTORESPONDER_REQUEST,
    putAutoresponderSaga
  );
}
