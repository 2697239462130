import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const signoutSelector = (state: any) => state.features.signout;

export const getBaseUrl = createSelector(signoutSelector, (app) => app.baseUrl);

export const getSignoutData = createSelector(
  signoutSelector,
  (app) => app.signoutData
);

export const getSignoutStates = createSelector(
  signoutSelector,
  (app) => app.signoutState
);

export const getSignoutLoadingState = createSelector(
  getSignoutStates,
  (signoutState) => signoutState?.isLoading
);
