import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  event: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const PostEventsSlice = createSlice({
  name: "postEvents",
  initialState: INITIAL_STATE,
  reducers: {
    postEventData: (state) => {
      state.event.isLoading = true;
    },
    postEventsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      event: {
        ...state.event,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    postEventsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      event: {
        ...state.event,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    putEventData: (state) => {
      state.event.isLoading = true;
    },
    putEventsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      event: {
        ...state.event,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    putEventsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      event: {
        ...state.event,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    deleteEventData: (state) => {
      state.event.isLoading = true;
    },
    deleteEventsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      event: {
        ...state.event,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    deleteEventsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      event: {
        ...state.event,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  postEventData,
  postEventsFailure,
  postEventsSuccess,
  putEventData,
  putEventsFailure,
  putEventsSuccess,
  deleteEventData,
  deleteEventsFailure,
  deleteEventsSuccess,
} = PostEventsSlice.actions;
export const PostEventsReducer = PostEventsSlice.reducer;
