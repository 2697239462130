import {
  CloseCircleFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PASSWORDREGEX } from "src/constants/common";
import SettingsLayout from "src/layouts/settings";
import { LocalStorageService } from "src/services/local-storage";
import RequestAppAction from "src/store/slices/appActions";
import "../setting.scss";

const { Title } = Typography;

const localStorageService = new LocalStorageService();

const Password: React.FC = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const dispatch = useDispatch();
  const [user, setUser] = useState<{
    username?: string | null;
    email: string | null;
  } | null>(null);

  const [visible, setVisible] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  });

  const getUser = async () => {
    await localStorageService.fetch("userData").then((res: any) => {
      if (res) {
        const data = JSON.parse(res);

        setUser(data?.data);
      }
    });
  };

  useLayoutEffect(() => {
    getUser();
  }, []);

  const onFinish = async (values: any) => {
    await form.validateFields();
    dispatch(
      RequestAppAction.changePassword({
        data: {
          currentPassword: values.currentPassword?.trim(),
          newPassword: values.newPassword?.trim(),
        },
        cbSuccess: () => {
          form.resetFields();
          notification.warning({
            message: t("notification.changedPasswordSuccess"),
          });
        },
        cbFailure: (e: any) => {
          notification.error({
            message: e,
          });
        },
      })
    );
  };

  const validateConfirmPassword = (_: any, value: string) => {
    const newPassword = form.getFieldValue("newPassword");

    if (value && newPassword !== value) {
      return Promise.reject(new Error(t("notification.passwordNotMatch")));
    } else {
      return Promise.resolve();
    }
  };

  const validatePassword = (_: any, value: string) => {
    if (!PASSWORDREGEX.test(value) && value?.length > 0) {
      return Promise.reject(new Error(t("message.passwordRegexError")));
    } else {
      return Promise.resolve();
    }
  };

  const clickClose = (name: string) => {
    form.setFieldsValue({
      [name]: "",
    });
  };

  return (
    <SettingsLayout classname="password-page">
      <Form form={form} onFinish={onFinish}>
        <Space direction="vertical" size={16} className="password-box">
          <Row justify="start">
            <Col span={24}>
              <Title level={4} className="font-size-18 font-weight-600">
                {t("heading.loggedInAsUser")} ({user?.username || user?.email})
                <span style={{ marginLeft: "0.3rem" }}>
                  {t("heading.changeYourPassword?")}
                </span>
              </Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Form.Item
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter your current password",
                  },
                ]}
              >
                <Input
                  type="password"
                  placeholder="Current Password"
                  suffix={
                    <CloseCircleFilled
                      onClick={() => clickClose("currentPassword")}
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  }
                  style={{ marginBottom: 9 }}
                />
              </Form.Item>

              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password",
                  },
                  { validator: validatePassword },
                ]}
              >
                <Input
                  placeholder="New Password"
                  type={visible.newPassword ? "input" : "password"}
                  prefix={
                    visible.newPassword ? (
                      <EyeOutlined
                        onClick={() => {
                          setVisible((pre) => ({
                            ...pre,
                            newPassword: !visible.newPassword,
                          }));
                        }}
                        className="site-form-item-icon"
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        onClick={() => {
                          setVisible((pre) => ({
                            ...pre,
                            newPassword: !visible.newPassword,
                          }));
                        }}
                        className="site-form-item-icon"
                      />
                    )
                  }
                  suffix={
                    <CloseCircleFilled
                      onClick={() => clickClose("newPassword")}
                      style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
                    />
                  }
                  style={{ marginBottom: 9 }}
                />
              </Form.Item>

              <Form.Item
                name="confirmNewPassword"
                dependencies={["newPassword"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password",
                  },
                  { validator: validateConfirmPassword },
                ]}
              >
                <Input
                  placeholder="Confirm New Password"
                  type={visible.confirmPassword ? "input" : "password"}
                  prefix={
                    visible.confirmPassword ? (
                      <EyeOutlined
                        onClick={() => {
                          setVisible((pre) => ({
                            ...pre,
                            confirmPassword: !visible.confirmPassword,
                          }));
                        }}
                        className="site-form-item-icon"
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        onClick={() => {
                          setVisible((pre) => ({
                            ...pre,
                            confirmPassword: !visible.confirmPassword,
                          }));
                        }}
                        className="site-form-item-icon"
                      />
                    )
                  }
                  suffix={
                    <CloseCircleFilled
                      onClick={() => clickClose("confirmNewPassword")}
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  }
                  style={{ marginBottom: 9 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="d-flex justify-content-end">
              <Button type="primary" htmlType="submit">
                {t("button.save")}
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </SettingsLayout>
  );
};

export default Password;
