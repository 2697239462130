import { createSelector } from "reselect";

const conversationsSelector = (state: TReduxState) =>
  state.features.conversation;

export const getBaseUrl = createSelector(
  conversationsSelector,
  (app) => app.baseUrl
);

export const getConversationsData = createSelector(
  conversationsSelector,
  (app) => app.apiStatus.data
);

export const getConversationsState = createSelector(
  conversationsSelector,
  (app) => app.conversations
);

export const getConversationsLoading = createSelector(
  getConversationsState,
  (states) => states?.isLoading
);
