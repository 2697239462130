import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  multipleMessage: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const PostMultipleMessagesSlice = createSlice({
  name: "postMultipleMessage",
  initialState: INITIAL_STATE,
  reducers: {
    postMultipleMessageData: (state) => {
      state.multipleMessage.isLoading = true;
    },
    postMultipleMessageSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      multipleMessage: {
        ...state.multipleMessage,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    postMultipleMessageFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      multipleMessage: {
        ...state.multipleMessage,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  postMultipleMessageData,
  postMultipleMessageFailure,
  postMultipleMessageSuccess,
} = PostMultipleMessagesSlice.actions;
export const PostMultipleMessagesReducer = PostMultipleMessagesSlice.reducer;
