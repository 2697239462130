import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  autoresponderKeyword: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const PostAutoresponderKeywordSlice = createSlice({ 
  name: "postAutoresponderKeyword",
  initialState: INITIAL_STATE,
  reducers: {
    postAutoresponderKeywordData: (state) => {
      state.autoresponderKeyword.isLoading = true;
    },
    postAutoresponderKeywordSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      autoresponderKeyword: {
        ...state.autoresponderKeyword,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    postAutoresponderKeywordFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      autoresponderKeyword: {
        ...state.autoresponderKeyword,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    putAutoresponderKeywordData: (state) => {
      state.autoresponderKeyword.isLoading = true;
    },
    putAutoresponderKeywordSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      autoresponderKeyword: {
        ...state.autoresponderKeyword,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    putAutoresponderKeywordFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      autoresponderKeyword: {
        ...state.autoresponderKeyword,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    deleteAutoresponderKeywordData: (state) => {
      state.autoresponderKeyword.isLoading = true;
    },
    deleteAutoresponderKeywordSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      autoresponderKeyword: {
        ...state.autoresponderKeyword,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    deleteAutoresponderKeywordFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      autoresponderKeyword: {
        ...state.autoresponderKeyword,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  postAutoresponderKeywordData,
  postAutoresponderKeywordFailure,
  postAutoresponderKeywordSuccess,
  putAutoresponderKeywordData,
  putAutoresponderKeywordFailure,
  putAutoresponderKeywordSuccess,
  deleteAutoresponderKeywordData,
  deleteAutoresponderKeywordFailure,
  deleteAutoresponderKeywordSuccess,
} = PostAutoresponderKeywordSlice.actions;
export const PostAutoresponderKeywordReducer = PostAutoresponderKeywordSlice.reducer;