import { createSelector } from "reselect";

const bulkContactSelector = (state: TReduxState) => state.features.contactBulk;

export const getBaseUrl = createSelector(
  bulkContactSelector,
  (app) => app.baseUrl
);

export const bulkContactData = createSelector(
  bulkContactSelector,
  (app) => app.apiStatus.data
);

export const bulkContactState = createSelector(
  bulkContactSelector,
  (app) => app.contacts
);

export const bulkContactLoading = createSelector(
  bulkContactState,
  (states) => states?.isLoading
);
