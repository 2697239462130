import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  filesState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: {
      items: [],
    },
  },
};

export const fetchUploadFilesSlice = createSlice({
  name: "upload",
  initialState: INITIAL_STATE,
  reducers: {
    fetchUploadFiles: (state) => {
      state.filesState.isLoading = true;
    },
    fetchUploadFilesSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      filesState: {
        ...state.filesState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchUploadFilesFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      filesState: {
        ...state.filesState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearFiles: (state) => ({
      ...state,
      filesState: {
        ...state.filesState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: { items: [] },
      },
    }),
  },
});

export const {
  fetchUploadFiles,
  fetchUploadFilesFailure,
  fetchUploadFilesSuccess,
  clearFiles,
} = fetchUploadFilesSlice.actions;
export const UploadFilesReducer = fetchUploadFilesSlice.reducer;
