import { createSelector } from "reselect";

const notesSelector = (state: TReduxState) => state.features.user;

export const getBaseUrl = createSelector(notesSelector, (app) => app.baseUrl);

export const getUser = createSelector(
  notesSelector,
  (app) => app.apiStatus.data
);

export const getUserState = createSelector(notesSelector, (app) => app.user);

export const getUserLoading = createSelector(
  getUserState,
  (states) => states?.isLoading
);
