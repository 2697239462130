import {  ProfileOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";

const TemplateBtn = ({
  templateMenu,
  style = { borderRadius: 50, marginLeft: 10 },
}: {
  items?: MenuProps['items'];
  templateMenu?: (originNode: ReactNode) => ReactNode;
  style?: any;
}) => {

  return (
    <Dropdown dropdownRender={templateMenu} placement="topRight" arrow>
      <Button
        icon={<ProfileOutlined />}
        style={style}
      />
    </Dropdown>
  );
};

export default TemplateBtn;
