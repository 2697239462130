import { createSelector } from "reselect";

const preferenceSelector:any = (state: TReduxState) => state.features.preference;

export const getBaseUrl = createSelector(preferenceSelector, (app) => app.baseUrl);

export const getPreferenceData = createSelector(
  preferenceSelector,
  (app) => app.apiStatus.data
);

export const getPreferenceState = createSelector(preferenceSelector, (app) => app.preference);

export const getPreferenceLoading = createSelector(
  getPreferenceState,
  (states) => states?.isLoading
);