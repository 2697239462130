import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  DeleteGroupContactState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const DeleteGroupContactSlice = createSlice({
  name: "deleteGroupContact",
  initialState: INITIAL_STATE,
  reducers: {
    fetchDeleteGroupContact: (state) => {
      state.DeleteGroupContactState.isLoading = true;
    },
    fetchDeleteGroupContactSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      DeleteGroupContactState: {
        ...state.DeleteGroupContactState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchDeleteGroupContactFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      DeleteGroupContactState: {
        ...state.DeleteGroupContactState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  fetchDeleteGroupContact,
  fetchDeleteGroupContactFailure,
  fetchDeleteGroupContactSuccess,
} = DeleteGroupContactSlice.actions;
export const DeleteGroupContactReducer = DeleteGroupContactSlice.reducer;
