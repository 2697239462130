import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CurrentDidState {
  id: number | null;
}

const initialState: CurrentDidState = {
  id: null,
};

const currentDidSlice = createSlice({
  name: "currentDid",
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    clearCurrentDid: (state) => {
      state.id = null;
    },
  },
});

export const { setId, clearCurrentDid } = currentDidSlice.actions;
export const currentDidReducer = currentDidSlice.reducer;
